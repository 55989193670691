/**
 * Transforms a record into a dropdown option.
 *
 * @param {Object} record - The record to format.
 * @param {string} record.name - The label for the dropdown option.
 * @param {string} record.crn - The value for the dropdown option.
 *
 * @returns {Object} The formatted dropdown option.
 */
const format = record => ({
  label: record.name || '',
  value: record.crn || '',
  ...(record?.locations && { locations: record.locations }),
  ...(record?.timeZone && { timeZone: record.timeZone }),
});

/**
 * Determines the response based on a filtering strategy, if provided.
 *
 * @param {Array} serviceResponse - An array of records.
 * @param {function} [strategy] - An optional filtering strategy function.
 *
 * @returns {Array} The transformed response.
 */
const determineResponse = (serviceResponse, strategy) => {
  return strategy
    ? serviceResponse.filter(strategy).map(record => format(record))
    : serviceResponse.map(record => format(record));
};

/**
 * Converts a service response into dropdown options using a filtering strategy.
 *
 * @param {Object} options - The options for converting to dropdown options.
 * @param {Array} [options.serviceResponse] - The array of service response records.
 * @param {function} [options.filteringStrategy] - An optional filtering strategy function.
 *
 * @returns {Array} The resulting dropdown options.
 */
export const toDropdownOptions = ({ serviceResponse = undefined, filteringStrategy = undefined }) => {
  return serviceResponse !== undefined && serviceResponse.length !== 0
    ? determineResponse(serviceResponse, filteringStrategy)
    : [];
};
