import {
  SearchRounded,
  HighlightOff,
  History,
  Clear,
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  PriceCheckOutlined,
  AttachMoneyOutlined,
  CurrencyExchangeOutlined,
  CreditCardOffOutlined,
  CheckCircleOutline,
  NotInterestedOutlined,
  StickyNote2Rounded,
  CheckOutlined,
  ExpandMoreRounded,
  ExpandLessRounded,
  DeleteRounded,
  AddCircleRounded,
  CancelRounded,
  ErrorOutlined,
  AddRounded,
  CancelOutlined,
  CloseOutlined,
  CategoryOutlined,
  FmdGoodOutlined,
  ModeEditOutlined,
  MonetizationOnOutlined,
  TuneOutlined,
  LocationCityOutlined,
  ContentPasteOutlined,
  DescriptionOutlined,
  AssignmentLateOutlined,
  LoopOutlined,
  CalendarMonthOutlined,
  PendingActionsOutlined,
  EditOffOutlined,
  AutorenewRounded,
  RefreshOutlined,
} from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Order } from './assets/icons/order-icon.svg';
import { ReactComponent as Receipt } from './assets/icons/receipt-icon.svg';
import { ReactComponent as Inventory } from './assets/icons/inventory-icon.svg';
import { ReactComponent as Truck } from './assets/icons/truck-icon.svg';
import { ReactComponent as Dashboard } from './assets/icons/dashboard-icon.svg';
import { ReactComponent as Box } from './assets/icons/box-icon.svg';
import { ReactComponent as Overview } from './assets/icons/overview-icon.svg';
import { ReactComponent as NoData } from './assets/icons/no-data-image.svg';

const iconNames = {
  search: props => <SearchRounded {...props} />,
  empty: props => <HighlightOff {...props} />,
  history: props => <History {...props} />,
  deleteHistory: props => <Clear {...props} />,
  order: props => <SvgIcon inheritViewBox component={Order} {...props} />,
  receipt: props => <SvgIcon inheritViewBox component={Receipt} {...props} />,
  arrowDown: props => <ArrowDropDown {...props} />,
  arrowUp: props => <ArrowDropUp {...props} />,
  arrowLeft: props => <KeyboardArrowLeftRounded {...props} />,
  arrowRight: props => <KeyboardArrowRightRounded {...props} />,
  priceStatus: props => <PriceCheckOutlined {...props} />,
  taxStatus: props => <AttachMoneyOutlined {...props} />,
  billableStatus: props => <CurrencyExchangeOutlined {...props} />,
  voidedStatus: props => <CreditCardOffOutlined {...props} />,
  isBillable: props => <CheckCircleOutline {...props} />,
  notBillable: props => <NotInterestedOutlined {...props} />,
  hasNotes: props => <StickyNote2Rounded {...props} />,
  check: props => <CheckOutlined {...props} />,
  calendar: props => <CalendarMonthOutlined {...props} />,
  expandMore: props => <ExpandMoreRounded {...props} />,
  expandLess: props => <ExpandLessRounded {...props} />,
  deleteItem: props => <DeleteRounded {...props} />,
  addItem: props => <AddCircleRounded {...props} />,
  cancelAddItem: props => <CancelRounded {...props} />,
  cancelOutlined: props => <CancelOutlined {...props} />,
  closeOutlined: props => <CloseOutlined {...props} />,
  add: props => <AddRounded {...props} />,
  circleExclamation: props => <ErrorOutlined {...props} />,
  product: props => <CategoryOutlined {...props} />,
  location: props => <FmdGoodOutlined {...props} />,
  dashboard: props => <SvgIcon inheritViewBox component={Dashboard} {...props} />,
  overview: props => <SvgIcon inheritViewBox component={Overview} {...props} />,
  box: props => <SvgIcon inheritViewBox component={Box} {...props} />,
  inventory: props => <SvgIcon inheritViewBox component={Inventory} {...props} />,
  truck: props => <SvgIcon inheritViewBox component={Truck} {...props} />,
  fileUpload: props => <FileUploadOutlinedIcon {...props} />,
  edit: props => <ModeEditOutlined {...props} />,
  monetization: props => <MonetizationOnOutlined {...props} />,
  tune: props => <TuneOutlined {...props} />,
  locationCity: props => <LocationCityOutlined {...props} />,
  contentBoard: props => <ContentPasteOutlined {...props} />,
  paper: props => <DescriptionOutlined {...props} />,
  warningBoard: props => <AssignmentLateOutlined {...props} />,
  refresh: props => <LoopOutlined {...props} />,
  commonRefresh: props => <RefreshOutlined {...props} />,
  noData: props => <SvgIcon inheritViewBox component={NoData} {...props} />,
  receiptStatus: props => <PendingActionsOutlined {...props} />,
  editOff: props => <EditOffOutlined {...props} />,
  loader: props => <AutorenewRounded {...props} />,
};

export default iconNames;
