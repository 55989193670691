import { css } from '@emotion/react';

export const DatePerDayPickerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  flex-direction: row;

  .datePicker {
    width: 220px;
    margin: 0 10px;
  }

  .ctas-btn {
    background-color: ${theme.colorsCA.blue[95]};
    border: none;
    min-width: 38px;
    padding: 0px;
    border-radius: 10px;
    height: 38px;

    .icon {
      color: ${theme.colorsCA.darkBlue[10]};
    }
  }

  .ctas-btn:hover {
    background-color: ${theme.colorsCA.blue[95]};
    border: none;
  }
`;
