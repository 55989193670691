import React, { useEffect } from 'react';
import { useWithBaseFieldHelper } from './withBaseField.helper';

/**
 * Enhances a given React component by providing form handling capabilities.
 *
 * It wraps the `WrappedComponent` with additional props such as `onChange`,
 * `onBlur`, `value`, `error`, and `helperText`.
 *
 * The form handling is powered by a `formhandler` instance implementing
 * specific methods.
 *
 * @param WrappedComponent - The React component to be enhanced with form
 * handling props.
 *
 * @returns The enhanced component with form handling props.
 */
export function withBaseField(WrappedComponent) {
  /**
   * The enhanced functional component with form handling capabilities.
   *
   * @param props - Props to be passed to the enhanced component.
   *
   * @returns JSX representing the enhanced component.
   */
  return function WithFormHandlerField(props) {
    const { debouncedBlurChange, debouncedHandleChange, setInitialValue, inputValue, handleBlur, handleChange } =
      useWithBaseFieldHelper(props);

    useEffect(() => {
      setInitialValue();

      // Clean up debounced functions when component is unmounted
      return function cleanup() {
        debouncedHandleChange.cancel();
        debouncedBlurChange.cancel();
      };
    }, [debouncedBlurChange, debouncedHandleChange, setInitialValue]);

    /**
     * Rewrites the additional props related to form handling to the
     * WrappedComponent.
     *
     * Note:
     * The slotProps property allows to notify when there was an error to the MUI DatePicker component
     * by modifying its styles; providing feedback to user.
     */
    return (
      <WrappedComponent
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        helperText={
          props.fieldprops?.meta.touched && props.fieldprops?.meta.error
            ? props.fieldprops?.meta.error
            : props.helperText
        }
        error={Boolean(props.fieldprops?.meta.touched && props.fieldprops?.meta.error)}
        {...(props.componenttype === ('DateField' || 'TimeField') && {
          slotProps: {
            textField: {
              size: props?.size,
              helperText:
                props.fieldprops?.meta.touched && props.fieldprops?.meta.error ? props.fieldprops?.meta.error : '',
              error: Boolean(props.fieldprops?.meta.touched && props.fieldprops?.meta.error),
            },
          },
        })}
      />
    );
  };
}
