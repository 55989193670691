import React from 'react';
import cn from 'classnames';
import { Formik, Form, Field } from 'formik';
import { Button, SelectField, DateField, TimeField, Stack, TextField, Icon } from 'for-sdk/common';
import { CORE_ENTITY } from 'core';
import { useFormContentHelper } from './UsageForm.helper';
import PropTypes from 'prop-types';

export const UsageForm = ({ mode, recordData, onSubmitCallback }) => {
  const {
    initialValues,
    validationSchema,
    translateMessage,
    isDisabled,
    formMode,
    onSubmit,
    locationsOptions,
    productsOptions,
    binsOptions,
    uomOptions,
    setLocationHelperTxt,
    setProductsHelperTxt,
    isLoading,
    isLoadingTrans,
  } = useFormContentHelper({
    formMode: mode,
    recordData,
    onSubmitCallback,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <CORE_ENTITY.Components.GridFormContainer>
            <CORE_ENTITY.Components.GridFormContent>
              {isLoadingTrans && <Icon type="loader" className={cn('loaderIcon')} />}
              <Field name="location">
                {props => (
                  <SelectField
                    fieldprops={props}
                    label={translateMessage('entity.invTrans.location')}
                    options={locationsOptions}
                    disabled={isDisabled || isLoading}
                    helperText={setLocationHelperTxt}
                  />
                )}
              </Field>
              <Field name="material">
                {props => (
                  <SelectField
                    fieldprops={props}
                    label={translateMessage('entity.invTrans.material')}
                    options={productsOptions}
                    disabled={isDisabled || isLoading}
                    helperText={setProductsHelperTxt}
                  />
                )}
              </Field>
              {binsOptions.length !== 0 && (
                <Field name="bins">
                  {props => (
                    <SelectField
                      fieldprops={props}
                      label={translateMessage('entity.receipt.bins')}
                      options={binsOptions}
                      disabled={isLoading}
                    />
                  )}
                </Field>
              )}
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                <Field name="date">
                  {props => (
                    <DateField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.date')}
                      disabled={isDisabled || isLoading}
                    />
                  )}
                </Field>
                <Field name="time">
                  {props => (
                    <TimeField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.time')}
                      disabled={isDisabled || isLoading}
                    />
                  )}
                </Field>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                <Field name="quantity">
                  {props => (
                    <TextField
                      fieldprops={props}
                      label={translateMessage('entity.invTrans.quantity')}
                      type="number"
                      disabled={isLoading}
                    />
                  )}
                </Field>
                <Field name="unit">
                  {props => (
                    <SelectField
                      fieldprops={props}
                      label={translateMessage('entity.invTrans.unit')}
                      options={uomOptions}
                      disabled={isLoading}
                    />
                  )}
                </Field>
              </Stack>
            </CORE_ENTITY.Components.GridFormContent>
            <CORE_ENTITY.Components.GridFormFooter
              withDeleteAction={{
                enabled: mode === 'edit',
                isDisabled: isLoading,
                onCloseHandler: () => {
                  console.log('onCloseHandler');
                },
                onConfirmHandler: () => {
                  console.log('onConfirmHandler');
                },
                strings: {
                  dialogTitle: translateMessage('view.usage.delete'),
                  deleteDialogMsg: translateMessage('info.usage.deleteMsg'),
                  deleteCtaLabel: translateMessage('view.usage.delete'),
                },
              }}
            >
              <Button
                className={cn('save-button')}
                type="submit"
                disabled={formMode === 'create' ? !isValid || !dirty || isLoading : !isValid || isLoading}
                variant="text"
              >
                {translateMessage('general.actions.save')}
              </Button>
            </CORE_ENTITY.Components.GridFormFooter>
          </CORE_ENTITY.Components.GridFormContainer>
        </Form>
      )}
    </Formik>
  );
};

UsageForm.propTypes = {
  mode: PropTypes.string,
  recordData: PropTypes.object,
  onSubmitCallback: PropTypes.func,
};
