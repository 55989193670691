import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apiQuery } from '../query';

export const useApiHook = () => {
  const { entityRef } = useParams();
  /**
   *
   * @param {object} args object with all filters for inventory transactions
   * @param {object} options object to custimize the useQuery behavior
   * @returns
   */
  const ListQuery = (args, options) =>
    useQuery(
      ['inventoryTransactions', `${entityRef}-${args.locationRef}-${args.productRef}`],
      () =>
        apiQuery.list({
          entityRef,
          locationRef: args.locationRef,
          productRef: args.productRef,
        }),
      options
    );

  const List = useMutation({
    mutationFn: ({ args }) =>
      apiQuery.list({
        entityRef,
        locationRef: args.locationRef,
        productRef: args.productRef,
        params: args.queryParams,
      }),
  });
  const ListByLocation = useMutation({
    mutationFn: ({ args }) =>
      apiQuery.listByLocation({
        entityRef,
        locationRef: args.locationRef,
        params: args.queryParams,
      }),
  });

  const Get = (locationRef, productRef, transactionRef, queryParams = {}) =>
    useQuery(['getInventoryTransaction', `${entityRef}-${transactionRef}`], () =>
      apiQuery.get({ entityRef, locationRef, productRef, transactionRef, params: queryParams })
    );
  const Create = (locationRef, productRef) =>
    useMutation({
      mutationFn: payload => apiQuery.create({ entityRef, locationRef, productRef, payload }),
    });
  const Update = () =>
    useMutation({
      mutationFn: args =>
        apiQuery.update({
          entityRef,
          locationRef: args.locationRef,
          productRef: args.productRef,
          transactionRef: args.transactionRef,
          payload: args.payload,
        }),
    });

  return {
    List,
    ListByLocation,
    ListQuery,
    Get,
    Create,
    Update,
  };
};
