export const entity = {
  receipt: {
    name: 'Recibo',
    material: 'Material',
    location: 'Ubicación',
    quantity: 'Cantidad',
    vendor: 'Vendedor',
    hauler: 'Transportista',
    vehicle: 'Vehículo',
    ticket: 'Tiquete',
    unit: 'Unidad',
    date: 'Fecha',
    time: 'Hora',
    purchaseOrder: 'Orden De Compra',
    receiptStatus: 'Estado Del Recibo',
    devices: 'Dispositivos',
    bins: 'Contenedor / Silo',
  },
};
