import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { Stack, Divider } from 'for-sdk/common';
import styled from '@emotion/styled';
import cn from 'classnames';
import { CollapsibleSkeletonStyles } from './style';
import PropTypes from 'prop-types';

const Styled = styled.div`
  ${CollapsibleSkeletonStyles}
`;

const CollapsibleSkeleton = ({ rows }) => {
  const skeleton = useMemo(() => {
    let elements = [];

    for (let i = 0; i < rows; i++) {
      elements.push(
        <Stack key={i + 1}>
          <Stack className={cn('wrapper-content')}>
            <Skeleton animation="wave" variant="rounded" width="100%" height={18} />
          </Stack>
          <Divider />
        </Stack>
      );
    }

    return elements;
  }, [rows]);

  return <Styled>{skeleton}</Styled>;
};

export default CollapsibleSkeleton;

CollapsibleSkeleton.propTypes = {
  rows: PropTypes.number,
};
