export const compareDates = (initDate, againstDate) => {
  const d1 = new Date(initDate);
  const d2 = new Date(againstDate);

  // Check is the dates are valid
  if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
    return 'INVALID';
  }

  const d1String = d1.toISOString().split('.')[0];
  const d2String = d2.toISOString().split('.')[0];

  // Compare Dates
  if (d1String > d2String) {
    return 'HIGHER';
  } else if (d1String === d2String) {
    return 'EQUAL';
  }

  return 'LOWER';
};
