import { INVTRANS_ENTITY } from 'entities/inventory-transaction';

export const component = {
  datePicker: {
    startDate: 'Select start date',
    endDate: 'Select end date',
    selectDate: 'Select date',
  },
  dateRangePicker: {
    invalidDate: 'The start date cannot be after the end date',
    invalidRange: 'The date range cannot exceed 31 days',
  },
  ...INVTRANS_ENTITY.i18n['en-US'].component,
};
