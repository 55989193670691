import { css } from '@emotion/react';

export const CardStyles = ({ theme }) => css`
  font-family: ${theme.typography.fontFamily};
  border-radius: 20px;
  height: 520px;
  width: 100%;
  padding: 0px 15px;
  margin-bottom: 5px;

  @media screen and (max-width: 1344px) {
    height: 380px;
  }
`;
