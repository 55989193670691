import { Button, DialogActions, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import { CORE_ENTITY } from 'core';
import styled from '@emotion/styled';
import { DateRangeButtonStyles, PopperStyles } from './style';

const StyledPopper = styled(Popper)`
  ${PopperStyles}
`;

const ButtonStyled = styled(DialogActions)`
  ${DateRangeButtonStyles}
`;

export const CustomPopper = props => {
  return <StyledPopper {...props}>{props.children}</StyledPopper>;
};

export const CustomActionBar = (props, value, handleCustomSearch) => {
  const { onAccept, className } = props;

  return (
    <ButtonStyled className={className}>
      <Button disabled={!CORE_ENTITY.Utils.checkNonNullInArray(value)} onClick={() => handleCustomSearch(onAccept)}>
        Done
      </Button>
    </ButtonStyled>
  );
};

CustomPopper.propTypes = {
  children: PropTypes.func,
};

CustomActionBar.propTypes = {
  className: PropTypes.string,
  onAccept: PropTypes.func,
};
