import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { DefaultTriggerStyle } from './style';

import Button from '../Button';
import Icon from '../Icon';

const Styled = styled(Button)`
  ${DefaultTriggerStyle}
`;

const DefaultTrigger = ({ translationId, onClick, disabled, startIcon, isOpen }) => {
  return (
    <Styled
      variant="outlined"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={<Icon type={isOpen ? 'arrowUp' : 'arrowDown'} />}
      pressed={isOpen}
      defaultActive
      disabled={disabled}
    >
      <Localization.Translate stringId={translationId} />
    </Styled>
  );
};

DefaultTrigger.propTypes = {
  translationId: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
  isOpen: PropTypes.bool,
};

export default DefaultTrigger;
