import { css } from '@emotion/react';

export const GridContainerStyles = ({ theme }) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 660px 96px;
  grid-template-areas:
    'content'
    'footer';

  @media screen and (max-width: 1600px) {
    grid-template-rows: 473px 96px;
  }

  @media screen and (max-width: 1344px) {
    grid-template-rows: 373px 70px;
  }
`;
