import { css } from '@emotion/react';

const pressedStyle = ({ theme }) => css`
  background: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.blue[90] })};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
  box-shadow: ${theme.validateMode({
    light: theme.shadowCA(1),
    dark: theme.shadowCA(3),
  })};
`;

const defaultActiveStyle = ({ theme }) => css`
  background: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.blue[90] })};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};

  &:hover {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.blue[80] })};
  }
`;

export const ButtonStyle = ({ theme, defaultActive, pressed }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 20px;
  border-radius: 100px;
  border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
  background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
  padding: 10px 24px;
  font-weight: 500;
  box-sizing: border-box;
  height: 40px;
  ${'' /* text-transform: revert; */}

  &:hover {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.surfaceCA(2) })};
    border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
  }

  ${defaultActive ? defaultActiveStyle({ theme }) : ''}

  ${pressed ? pressedStyle({ theme }) : ''}
`;
