import React from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import { Stack } from 'for-sdk/common';
import { CardHeaderStyles } from './style';
import PropTypes from 'prop-types';

const Styled = styled(Stack)`
  ${CardHeaderStyles}
`;

export const CardHeader = ({ materialTitle, materialId, actionButtons }) => {
  return (
    <Styled direction="row" justifyContent="space-between" alignItems="center">
      <div>
        <h2>{materialTitle}</h2>
        <p className={cn('header-matId')}>{materialId}</p>
      </div>
      <Stack direction="row" alignItems="center" gap={1}>
        {actionButtons}
      </Stack>
    </Styled>
  );
};

CardHeader.propTypes = {
  materialTitle: PropTypes.string,
  materialId: PropTypes.string,
  actionButtons: PropTypes.element,
};
