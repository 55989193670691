import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apiQuery } from '../query';

export const useApiHook = () => {
  const { entityRef } = useParams();

  const List = ({ adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['uom', entityRef],
      queryFn: () =>
        apiQuery.list({
          entityRef,
        }),
    });

    return !adapter ? queryResult : { ...queryResult, data: adapter({ serviceResponse: queryResult.data }) };
  };

  return {
    List,
  };
};
