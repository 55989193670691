import React from 'react';
import { CORE_ENTITY } from 'core';
import { useInvOverviewDataContext } from '../../providers/overview-data-provider';

export const useProductRow = ({ productData }) => {
  const { name, inventoryBalance, stocks, transactions, demand } = productData;
  const maxStock = stocks?.length !== 0 && stocks[0]?.maxStock ? stocks[0].maxStock : 0;
  const safetyStock = stocks?.length !== 0 && stocks[0]?.safetyStock ? stocks[0].safetyStock : 0;
  const reOrderStock = stocks?.length !== 0 && stocks[0]?.reorderStock ? stocks[0].reorderStock : 0;
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { listInvTransactions, isLoadingTrans, showChartDemand, locationTimeZone } = useInvOverviewDataContext();
  const [productRef, setProductRef] = React.useState('');
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [graphData, setGraphData] = React.useState([]);
  const [axisRange, setAxisRange] = React.useState({ minYAxis: 0, maxYAxis: maxStock });
  const increaseXYaxis = 200;

  const getTotalOnHand = () => {
    const sortBalances = CORE_ENTITY.Utils.sortByKey(inventoryBalance, 'modifyDate');
    const silos = stocks?.length !== 0 && stocks[0]?.inventoryContainers ? stocks[0].inventoryContainers : [];

    if (silos.length !== 0) {
      let totalQuantity = 0;
      const siloBalance = silos.map(silo => {
        const balance = sortBalances.filter(el => el.inventoryContainer.toUpperCase() === silo.toUpperCase());
        totalQuantity += balance.length !== 0 ? balance[balance.length - 1].onHandQuantity.value : 0;
        return {
          name: silo.toUpperCase(),
          quantity: balance.length !== 0 ? balance[balance.length - 1].onHandQuantity.value : 0,
          uomCode: balance.length !== 0 ? balance[balance.length - 1].onHandQuantity.uomCode : 'STN',
        };
      });

      return { totalQuantity, silos: siloBalance };
    }

    return {
      totalQuantity: sortBalances[inventoryBalance.length - 1].onHandQuantity.value,
      silos: [],
    };
  };

  const onHandSilos = getTotalOnHand();
  const onHandStock = onHandSilos.totalQuantity;

  const stockRules = () => {
    let stockLvl = '';
    if (stocks?.length !== 0) {
      const { maxStock, safetyStock } = stocks[0];
      if (onHandStock <= 0) {
        stockLvl = translateMessage('entity.invOverview.outOfStock');
      }
      if (onHandStock > 0 && onHandStock <= safetyStock) {
        stockLvl = translateMessage('entity.invOverview.safetyStock');
      }
      if (onHandStock > maxStock) {
        stockLvl = translateMessage('entity.invOverview.overStock');
      }
    }

    return stockLvl;
  };

  const applyStockColor = () => {
    switch (stockRules()) {
      case translateMessage('entity.invOverview.outOfStock'):
        return {
          danger: true,
        };
      case translateMessage('entity.invOverview.safetyStock'):
        return {
          warning: true,
        };
      case translateMessage('entity.invOverview.overStock'):
        return {
          over: true,
        };
      default:
        return {};
    }
  };

  const supplyAmount = () => {
    return transactions.reduce((total, transaction) => {
      if (transaction.quantity && typeof transaction.quantity.value === 'number') {
        return total + transaction.quantity.value;
      }
      return total;
    }, 0);
  };

  const calculateStockByTransType = (data, currentStock) => {
    //Remove STOCK_TAKE and ADJUSTMENTS (FOR NOW)
    const dataSet = data.filter(
      trans => trans.type.toUpperCase() !== 'STOCK_TAKE' && trans.type.toUpperCase() !== 'ADJUSTMENT'
    );

    let quantity = currentStock;
    let withConfirmed = currentStock;
    let withAllDemand = currentStock;
    const result = dataSet.map(item => {
      // Calculates onHand level based on receipts and usages
      if (item.type === 'receipt') {
        quantity += item.transQuantity;
        withConfirmed += item.transQuantity;
        withAllDemand += item.transQuantity;
      } else if (item.type === 'usage') {
        quantity -= item.transQuantity;
        withConfirmed -= item.transQuantity;
        withAllDemand -= item.transQuantity;
      }

      // Calculates onHand level based on scheduled demand
      if (item.type === 'confirmed' || item.type === 'will call') {
        withAllDemand -= item.demandQuantity;
      }
      if (item.type === 'confirmed') {
        withConfirmed -= item.demandQuantity;
      }

      // Add properties according to the transaction type and demand status
      return {
        ...item,
        stockLvl: quantity,
        withConfirmedLvl: withConfirmed,
        withAllDemandLvl: withAllDemand,
      };
    });

    return result;
  };

  const onHandStockLevel = (invTransData, demandData, onHandAmount) => {
    const mappedTransactions = invTransData.map(el => ({
      transQuantity: el?.quantity?.value ? el.quantity.value : 0,
      transDateTime: CORE_ENTITY.Utils.dateTimeZone(el.transDateTime, locationTimeZone.current),
      type: el.type.toLowerCase(),
    }));

    const mappedDemand = demandData.map(el => {
      return {
        demandQuantity: el?.quantity ? el.quantity : 0,
        transDateTime: CORE_ENTITY.Utils.dateTimeZone(el.loadDateTime, locationTimeZone.current),
        type: el.status.toLowerCase(),
      };
    });

    const sortCompleteData = CORE_ENTITY.Utils.sortByKey([...mappedTransactions, ...mappedDemand], 'transDateTime');

    return calculateStockByTransType(sortCompleteData, onHandAmount);
  };

  const axisRangeByStockLevel = data => {
    if (data.length !== 0) {
      let values = [];
      data.forEach(obj => {
        const { transDateTime, type, ...quantities } = obj;
        values = values.concat(Object.values(quantities));
      });

      const min = Math.round(Math.min(...values));
      const max = Math.round(Math.max(...values));

      return {
        minYAxis: min <= 0 ? min - increaseXYaxis : 0,
        maxYAxis: max >= maxStock ? max + increaseXYaxis : onHandStock >= maxStock ? onHandStock : maxStock,
      };
    }
    return { minYAxis: 0, maxYAxis: onHandStock >= maxStock ? onHandStock + increaseXYaxis : maxStock };
  };

  const showProductGraph = async item => {
    if (productRef === '') {
      setIsExpanded(true);
      setProductRef(item);
      // Get all transactions & demand
      const transactions = await listInvTransactions(item);
      const demandScheduled = showChartDemand && demand?.perScheduledDelivery ? demand.perScheduledDelivery : [];
      const transDataChart = onHandStockLevel(transactions, demandScheduled, onHandStock);
      const getAxisRange = axisRangeByStockLevel(transDataChart);
      setAxisRange(getAxisRange);
      setGraphData(transDataChart);
    } else {
      setIsExpanded(false);
      setProductRef('');
    }
  };

  return {
    productName: name,
    onHandQuantity: onHandSilos,
    maxStockQuantity: maxStock,
    safestyStockQuantity: safetyStock,
    reorderStockQuantity: reOrderStock,
    stockLevel: stockRules(),
    enRouteReceipts: transactions,
    applyStockColor,
    supplyQuantity: supplyAmount() !== 0 ? supplyAmount().toFixed(2) : 0,
    demandQuantity: demand?.totalQuantity ? demand.totalQuantity : 0,
    showProductGraph,
    productRef,
    isExpanded,
    isLoadingTrans,
    graphData,
    axisRange,
  };
};
