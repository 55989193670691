import { css } from '@emotion/react';

export const DateRangeStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 1280px) {
    width: 400px;
  }

  .MuiStack-root {
    background: ${theme.backgroundMode};
    border-radius: 20px 20px 0px 0px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    border-bottom: none;
    bottom: 0;
    box-shadow: 0px 4px 20px 0px rgba(101, 119, 136, 0.2);
    padding: 20px;
    position: fixed;
    z-index: 12;
    width: 600px;
  }

  .icon {
    font-size: 22px;
    margin-right: 10px;
  }
`;

export const DateRangeButtonStyles = ({ theme }) => css`
  border-top: 1px solid #e1e1e1;

  .MuiButtonBase-root {
    background: ${theme.colorsCA.blue[50]};
    border-radius: 100px;
    color: ${theme.colorsCA.neutral[100]};

    &:hover {
      background: ${theme.colorsCA.blue[50]};
    }

    &:disabled {
      background: ${theme.colorsCA.neutral[60]};
      color: ${theme.colorsCA.neutral[10]};
    }
  }

  @media screen and (max-width: 1280px) {
    .MuiButtonBase-root {
      height: 20px;
    }
  }
`;

export const PopperStyles = ({ theme }) => css`
  margin-bottom: 20px !important;
  z-index: 20;

  .MuiPaper-root {
    background: ${theme.backgroundMode};
    border-radius: 20px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
  }

  @media screen and (max-width: 1280px) {
    .MuiPickersArrowSwitcher-root {
      padding: 0;
    }

    .MuiPickersSlideTransition-root {
      min-height: 204px;
    }
  }
`;
