import React from 'react';
import styled from '@emotion/styled';
import { GridContentStyles } from './style';
import { Stack } from 'for-sdk/common';

const Styled = styled(Stack)`
  ${GridContentStyles}
`;

export const GridFormContent = ({ children }) => <Styled>{children}</Styled>;
