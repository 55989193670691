export const OverviewStyles = ({ theme }) => `
  overflow: initial !important;

  .filters-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 20px 0px 0px;

    .ctas-content: {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .refresh-button {
      margin-left: 10px;
      background-color: ${theme.colorsCA.blue[95]};
      border: none;
      min-width: 48px;
      padding: 0px;
      border-radius: 100%;
      height: 48px;

      .icon {
        color: ${theme.colorsCA.darkBlue[10]};
      }
    }
  }

  .noProducts {
    margin: 0;
    text-align: center;
  }
`;
