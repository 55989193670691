import { useState } from 'react';
import { CORE_ENTITY } from 'core';

export const useFooterHelper = ({ withDeleteAction }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { translateToken } = CORE_ENTITY.Utils.useLocalizationProvider();

  /**
   * handleDialogConfirm is a method in charge of handling
   * the delete request after confirmation by the user.
   */
  const handleDialogConfirm = () => {
    withDeleteAction.onConfirmHandler();
  };

  /**
   * handleDialogCancel is a method in charge of handling
   * the dialog cancel option to close the dialog
   * after confirmation by the user.
   */
  const handleDialogClose = () => {
    setOpenDialog(!openDialog);
    withDeleteAction.onCloseHandler();
  };

  return {
    openDialog,
    handleDialogConfirm,
    handleDialogClose,
    translateMessage: translateToken,
  };
};
