import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  defaultLocationOptions,
  defaultProductOptions,
  defaultTypeOptions,
  defaultVendorOptions,
  defaultReceiptStatusOptions,
} from './filtersConfig';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { INVOVERVIEW_ENTITY } from 'entities/inventory-overview';
import { LOCATION_ENTITY } from 'entities/location';
import { PRODUCT_ENTITY } from 'entities/product';
import { Adapters } from '../../api';

export const useFiltersHelper = ({ source }) => {
  const [locationFilterValue, setLocationFilterValue] = useState(defaultLocationOptions[0].value);
  const [materialFilterValue, setMaterialFilterValue] = useState(defaultProductOptions[0].value);
  const [typeFilterValue, setTypeFilterValue] = useState(defaultTypeOptions[0].value);
  const [vendorFilterValue, setVendorFilterValue] = useState(defaultVendorOptions[0].value);
  const [receiptStatusFilterValue, setReceiptStatusFilterValue] = useState(defaultReceiptStatusOptions[0].value);
  const [running, setRunning] = useState(true);

  const locationsOptions = LOCATION_ENTITY.appLogic().List({
    adapter: Adapters.toDropdownOptions,
  }).data;
  const productsOptions = PRODUCT_ENTITY.appLogic().ListInvProducts({
    adapter: Adapters.toDropdownOptions,
  }).data;
  const { handleChangeFilters } = INVTRANS_ENTITY.Components.useTransactionsDataContext();
  const { handleChangeFilters: handleChangeOverview } = INVOVERVIEW_ENTITY.Providers.useInvOverviewDataContext();
  const [filtersQuery, setFiltersQuery] = useState({
    locationRef: '',
    productRef: '',
    typeRef: '',
    vendorRef: '',
    receiptStatusRef: '',
  });

  const locationSelectorOptions = useMemo(() => {
    if (locationsOptions.length !== 0) {
      let allLocations = [];
      allLocations =
        source === 'inventoryTransaction' ? [...locationsOptions] : [...defaultLocationOptions, ...locationsOptions];
      return allLocations;
    }

    return defaultLocationOptions;
  }, [locationsOptions, source]);

  const materialSelectorOptions = useMemo(() => {
    if (productsOptions.length !== 0) {
      let allProducts = [];
      allProducts = [...defaultProductOptions, ...productsOptions];
      return allProducts;
    }

    return defaultProductOptions;
  }, [productsOptions]);

  const sendDataToContext = useCallback(() => {
    switch (source) {
      case 'inventoryTransaction':
        handleChangeFilters(filtersQuery);
        break;
      case 'inventoryOverview':
        handleChangeOverview(filtersQuery);
        break;
      default:
        break;
    }
  }, [filtersQuery, handleChangeFilters, handleChangeOverview, source]);

  /**
   * Handle different providers to
   * filter transactions and inventory overview data
   */
  useEffect(() => {
    sendDataToContext();
  }, [filtersQuery, sendDataToContext]);

  /**
   * Sets a default location filter using the
   * first location returned by the endpoint.
   */
  useEffect(() => {
    if (locationsOptions.length !== 0 && running && source === 'inventoryTransaction') {
      setRunning(false);
      setLocationFilterValue(locationsOptions[0].value);
      setFiltersQuery(prevState => ({
        ...prevState,
        locationRef: locationsOptions[0].value,
        productRef: 'All',
      }));
    }
  }, [locationsOptions, running, source]);

  const handleChangeLocation = useCallback(
    option => {
      setLocationFilterValue(option.value);
      setFiltersQuery(prevState => ({
        ...prevState,
        locationRef: option.value,
      }));
    },
    [setFiltersQuery]
  );

  const handleChangeMaterial = useCallback(
    option => {
      setMaterialFilterValue(option.value);
      setFiltersQuery(prevState => ({
        ...prevState,
        productRef: option.value,
      }));
    },
    [setFiltersQuery]
  );

  const handleChangeType = useCallback(option => {
    setTypeFilterValue(option.value);
    setFiltersQuery(prevState => ({
      ...prevState,
      typeRef: option.value,
    }));
  }, []);

  const handleChangeVendor = useCallback(option => {
    setVendorFilterValue(option.value);
  }, []);

  const handleChangeReceiptStatus = useCallback(option => {
    setReceiptStatusFilterValue(option.value);
    setFiltersQuery(prevState => ({
      ...prevState,
      receiptStatusRef: option.value,
    }));
  }, []);

  const filterByLocation = useCallback(() => {
    if (locationFilterValue !== 'All' && materialSelectorOptions.length !== 0) {
      return materialSelectorOptions.filter(
        product => product.locations?.all || product.locations[locationFilterValue]
      );
    }

    return materialSelectorOptions;
  }, [locationFilterValue, materialSelectorOptions]);

  return {
    locationSelectorOptions,
    materialSelectorOptions: filterByLocation(),
    typeSelectorOptions: defaultTypeOptions,
    vendorSelectorOptions: defaultVendorOptions,
    receiptStatusSelectorOptions: defaultReceiptStatusOptions,
    locationFilterValue,
    materialFilterValue,
    typeFilterValue,
    vendorFilterValue,
    receiptStatusFilterValue,
    handleChangeLocation,
    handleChangeMaterial,
    handleChangeType,
    handleChangeVendor,
    handleChangeReceiptStatus,
  };
};
