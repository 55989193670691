import { css } from '@emotion/react';

export const AccordionStyles = ({ theme, styletheme }) => css`
  width: 100%;

  h2 {
    font-family: ${theme.typography.fontFamily};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[100] })};
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    margin: 0px;
  }

  .MuiAccordionSummary-root {
    background: ${styletheme === 'main'
      ? theme.validateMode({ light: theme.colorsCA.neutral[99], dark: theme.backgroundCA(4) })
      : theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(4) })};
    height: 74px;
    ${styletheme === 'second' && 'display: none'};
  }

  .MuiAccordionDetails-root {
    padding: 8px 0px 15px;
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
  }
`;
