import React from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'connex-cds';
import menuConfig from '../../menu-config';
const { IFrameAuthenticated } = Auth;

export const AuthenticatedRoutes = () => {
  const { entityRef } = useParams();

  return <IFrameAuthenticated entityRef={entityRef} menuConfig={menuConfig} />;
};
