import { css } from '@emotion/react';

export const StockStyles = ({ theme }) => css`
  font-size: 16px;
  line-height: 24px;
  font-family: ${theme.typography.fontFamily};

  p {
    font-weight: 600;
    margin: 0px;
    color: ${theme.colorsCA.neutral[10]};
  }

  span {
    font-weight: 400;
    color: ${theme.colorsCA.neutral[30]};
  }
`;
