/**
 * Include all translation keys serving a 'general' purpose, they can be part of
 * a specific implementation but their main responsibility is to add value to any
 * key requiring these pieces of translation to be displayed as they are expected
 * to or, simply to provide a 'general' string to a component.
 */
export const general = {
  time_period_option: {
    today: 'Today',
    yesterday: 'Yesterday',
    this_week: 'This week',
    this_month: 'This month',
    last_month: 'Last month',
    custom_dates: 'Custom',
  },
  actions: {
    save: 'Save',
    confirm: 'Confirm',
    search: 'Search',
    cancel: 'Cancel',
    done: 'Done',
    addInventory: 'Add Into Inventory',
  },
  filter: {
    all: 'All',
  },
};
