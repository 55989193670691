import { css } from '@emotion/react';

export const DataGridTableStyles = ({ theme, loading, rows }) => css`
  flex: initial;
  border-radius: 10px;
  border: 1px solid ${theme.colorsCA.neutral[50]};
  box-shadow: ${theme.validateMode({ light: '0px 4px 20px rgba(101, 119, 136, 0.2)', dark: theme.shadowCA(4) })};
  margin: 20px 20px 20px 10px;
  overflow: hidden;
  height: ${loading ? '100%' : 'auto'};
  min-height: ${rows.length !== 0 ? 'auto' : '390px'};
  max-height: 100%;

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  > .MuiDataGrid-main {
    > .MuiDataGrid-columnHeaders {
      border-bottom: 1px solid ${theme.colorsCA.neutral[50]};
      box-shadow: ${theme.shadowCA(1)};
      z-index: 3;
      overflow: hidden;
      background: ${theme.backgroundCA('tableHF')};
      .MuiDataGrid-columnHeader {
        padding: 0 12px;
        overflow: hidden;
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: -4px;
          width: 4px;
          height: 100%;
          background: ${theme.validateMode({
            light: 'rgba(0, 0, 0, 0.25)',
            dark: 'rgba(255, 254, 254, 0.25)',
          })};
          filter: blur(2px);
        }

        &:first-of-type,
        &:last-of-type {
          &::before {
            display: none;
          }
        }
        &:not(.MuiDataGrid-columnHeader--alignCenter) {
          .MuiDataGrid-columnHeaderTitleContainer {
            justify-content: space-between;
          }
        }
        &:first-of-type {
          padding: 0 0 0 10px;
        }

        &:hover {
          .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-menuIcon {
            margin: 0;
          }
        }

        .MuiDataGrid-columnHeaderTitleContainer {
          .MuiDataGrid-iconButtonContainer {
            svg {
              fill: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
            }
          }

          .MuiDataGrid-columnHeaderTitle {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            margin: 0;
            text-transform: capitalize;
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[90] })};
          }
        }

        .MuiDataGrid-menuIcon {
          margin: 0;
          .MuiDataGrid-menuIconButton {
            background: ${theme.validateMode({
              light: theme.colorsCA.neutral[95],
              dark: theme.colorsCA.neutral[20],
            })};
          }
          svg {
            fill: ${theme.validateMode({ light: '#79747E', dark: theme.colorsCA.neutral[90] })};
          }
        }
      }
    }

    > .MuiDataGrid-virtualScroller {
      height: 100%;
      overflow: hidden;
      // firefox custom scroll styles
      scrollbar-color: #a6a7a9 #e7e7e7;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e7e7e7;
        border-radius: 8px;
        border: 1px solid #cacaca;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a6a7a9;
        border-radius: 8px;
      }

      &:hover {
        overflow: auto;
      }

      .MuiDataGrid-row.main-grid-row {
        border-bottom: 1px solid
          ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 400;

        &.MuiDataGrid-row--detailPanelExpanded,
        &:last-of-type {
          border-bottom: none;
        }
        &.Mui-selected {
          background: ${theme.validateMode({
            light: theme.colorsCA.blue[95],
            dark: 'linear-gradient(0deg, rgba(169, 229, 255, 0.31) 0%, rgba(169, 229, 255, 0.31) 100%), #434247)',
          })};
          &.voided {
            background: ${theme.validateMode({ light: '#FFF6F7', dark: '#986977' })};
          }
        }
        &:hover {
          background: ${theme.validateMode({
            light: theme.surfaceCA(1),
            dark: 'linear-gradient(0deg, rgba(208, 188, 255, 0.14) 0%, rgba(208, 188, 255, 0.14) 100%), #434247',
          })};
          &.voided {
            background: ${theme.validateMode({ light: '#FFF6F7', dark: '#633B48' })};
          }
        }

        &.voided {
          background: ${theme.validateMode({ light: '#FFF6F7', dark: '#492532' })};
        }

        .MuiDataGrid-cell {
          border: none;

          .detail-toggler-button {
            width: 24px;
            height: 24px;
          }

          .icon {
            &.state {
              color: ${theme.colorsCA.red[60]};
              font-size: 25px;
            }

            &.billable {
              color: ${theme.colorsCA.green[60]};
            }

            &.notes {
              color: ${theme.colorsCA.blue[50]};
            }
          }

          .MuiDataGrid-cellContent {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            white-space: pre-wrap;
          }
        }
      }

      .MuiDataGrid-detailPanel {
        overflow: initial;
        // firefox custom scroll styles
        scrollbar-color: #a6a7a9 #e7e7e7;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 6px;
          height: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: #e7e7e7;
          border-radius: 8px;
          border: 1px solid #cacaca;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #a6a7a9;
          border-radius: 8px;
        }
      }
    }
  }

  > .MuiDataGrid-footerContainer {
    z-index: 3;
    background: ${theme.backgroundCA('tableHF')};
    box-shadow: ${theme.validateMode({
      light: '0px -1px 3px 1px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.3)',
      dark: '0px -1px 2px 0px rgba(0, 0, 0, 0.30), 0px -1px 3px 1px rgba(0, 0, 0, 0.15)',
    })};
    border-top: 1px solid ${theme.colorsCA.neutral[50]};
    padding: 0 34px;
    p {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[0] })};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
`;

export const DataGridActionsStyles = ({ theme }) => css`
  cursor: pointer;
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[0], dark: theme.colorsCA.neutral[90] })};
`;

export const NoRowDataStyles = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 18px;

  .title {
    color: ${theme.colorsCA.neutral[50]};
    font-size: 22px;
    font-weight: 600;
  }

  .icon {
    width: 170px;
    height: auto;
    color: ${theme.colorsCA.neutral[70]};
  }
`;
