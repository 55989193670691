import { useState, useCallback, useMemo, useRef } from 'react';
import { TransactionsDataContext } from '.';
import { getFilteredDataByProperties } from '../search-filter';
import { useApiHook } from '../../api';

export const TransactionDataProvider = ({ children }) => {
  const { mutateAsync: listTransactions } = useApiHook().List;
  const { mutateAsync: listTransByLocation } = useApiHook().ListByLocation;
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateOption, setDateOption] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const selectedFilters = useRef({
    locationRef: '',
    productRef: 'All',
    typeRef: 'All',
    receiptStatusRef: 'All',
  });

  const ListTransactionByLocation = useCallback(data => {
    if (data && data.length !== 0) {
      setTransactions(data);
    } else {
      setTransactions([]);
    }
    setIsLoading(false);
  }, []);

  const filteredDataByType = useCallback((transactions, type) => {
    const byType = transactions.filter(el => el.type.toLowerCase() === type.toLowerCase());
    return byType;
  }, []);

  const filteredDataByReceiptStatus = useCallback((transactions, status) => {
    const byStatus = transactions.filter(el =>
      el.receiptStatus ? el.receiptStatus.toLowerCase() === status.toLowerCase() : false
    );
    return byStatus;
  }, []);

  const handleChangeFilters = useCallback(
    async filters => {
      ListTransactionByLocation([]);
      if (filters?.locationRef !== '' && filters?.productRef !== '') {
        try {
          setIsLoading(true);
          let transactionResponse = [];
          selectedFilters.current = filters;
          const params = { ...filters, queryParams: dateOption };

          if (filters.productRef !== 'All') {
            transactionResponse = await listTransactions({ args: params });
          } else {
            transactionResponse = await listTransByLocation({ args: params });
          }

          let filteredData = transactionResponse;
          if (filters?.typeRef !== '' && filters.typeRef !== 'All') {
            filteredData = filteredDataByType(transactionResponse, filters.typeRef);
          }

          if (filters?.receiptStatusRef !== '' && filters.receiptStatusRef !== 'All') {
            filteredData = filteredDataByReceiptStatus(transactionResponse, filters.receiptStatusRef);
          }

          ListTransactionByLocation(filteredData);
        } catch (error) {
          setIsLoading(true);
          ListTransactionByLocation([]);
        }
      }
    },
    [
      listTransactions,
      listTransByLocation,
      ListTransactionByLocation,
      dateOption,
      filteredDataByType,
      filteredDataByReceiptStatus,
    ]
  );

  const SearchQueryTransaction = useCallback(searchKey => {
    setSearchQuery(searchKey);
  }, []);

  const filteredDataBySearch = useMemo(
    () =>
      getFilteredDataByProperties({
        query: searchQuery,
        data: transactions,
        properties: [
          'inventoryProductDescription',
          'locationName',
          'type',
          'ticketId',
          'supplierName',
          'inventoryContainer',
          'adjustmentType',
          'receiptStatus',
        ],
      }),
    [transactions, searchQuery]
  );

  const refreshTransactions = useCallback(() => {
    handleChangeFilters(selectedFilters.current);
  }, [handleChangeFilters]);

  const value = useMemo(() => {
    return {
      transactions: filteredDataBySearch,
      ListTransactionByLocation,
      SearchQueryTransaction,
      handleChangeFilters,
      setDateOption,
      isLoading,
      refreshTransactions,
    };
  }, [
    ListTransactionByLocation,
    SearchQueryTransaction,
    filteredDataBySearch,
    handleChangeFilters,
    setDateOption,
    isLoading,
    refreshTransactions,
  ]);

  return <TransactionsDataContext.Provider value={value}>{children}</TransactionsDataContext.Provider>;
};
