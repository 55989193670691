import { css } from '@emotion/react';

export const CollapsibleSkeletonStyles = ({ theme }) => css`
  .wrapper-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 64px;
    align-items: center;
    padding: 0px 15px;
  }
`;
