import { Components } from './components';
import { Providers } from './providers';
import { i18n } from './i18n';
import { Views } from './views';
import { useApiHook } from './api';

export const INVOVERVIEW_ENTITY = {
  Components,
  Providers,
  i18n,
  Views,
  appLogic: useApiHook,
};
