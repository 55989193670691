import React, { useMemo, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Localization } from 'connex-cds';
import Icon from '../../common/Icon';
import { CustomPopper, CustomActionBar } from './CustomDateComponents';
import { CORE_ENTITY } from 'core';
import { supportedLocales } from 'core/i18n/common';
import styled from '@emotion/styled';
import { DateRangeStyles } from './style';

const Styled = styled.div`
  ${DateRangeStyles}
`;

const DateRangePicker = ({ onCustomDateFilterTypeChange, value, setValue }) => {
  const [calendarOpen, setCalendarOpen] = useState(true);
  const { userLocale } = Localization.useLocalizationContext();
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(() => {
    return {
      start: translateMessage('component.datePicker.startDate'),
      end: translateMessage('component.datePicker.endDate'),
    };
  }, [translateMessage]);

  const handleClose = () => setCalendarOpen(false);
  const handleOpen = () => setCalendarOpen(true);
  const handleOnChange = newValue => setValue(() => newValue);

  const handleCustomSearch = onAccept => {
    if (value.length > 0 && CORE_ENTITY.Utils.checkNonNullInArray(value)) {
      const startDate = new Date(value[0].$d).toISOString();

      const endDateTimestamp = new Date(value[1].$d).setHours(23, 59, 59, 999);
      const endDate = new Date(endDateTimestamp).toISOString();

      onCustomDateFilterTypeChange({ startDate, endDate });
    }
    onAccept();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Styled>
        <MuiDateRangePicker
          format={supportedLocales?.[userLocale]?.formats?.L || 'MM/DD/YYYY'}
          localeText={localeText}
          onClose={handleClose}
          closeOnSelect={false}
          onOpen={handleOpen}
          open={calendarOpen}
          onChange={handleOnChange}
          maxDate={dayjs()}
          calendars={2}
          slots={{
            actionBar: props => CustomActionBar(props, value, handleCustomSearch),
            popper: CustomPopper,
          }}
          slotProps={{
            textField: { InputProps: { startAdornment: <Icon type="calendar" /> } },
            actionBar: {
              actions: ['accept'],
            },
          }}
          value={value}
        />
      </Styled>
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  onCustomDateFilterTypeChange: PropTypes.func,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default DateRangePicker;
