export const entity = {
  adjustment: {
    name: 'Ajuste',
    material: 'Material',
    location: 'Ubicación',
    type: 'Tipo de Ajuste',
    quantity: 'Cantidad',
    currentOnHand: 'Disponible Actual',
    unit: 'Unidad',
    date: 'Fecha',
    time: 'Hora',
    devices: 'Dispositivos',
    bins: 'Contenedor / Silo',
  },
};
