import { http } from 'connex-cds';
import { CORE_ENTITY } from 'core';

export const apiQuery = {
  list: ({ entityRef, params }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/inventory-overview`,
      queryParams: {
        ...params,
      },
    }),
};
