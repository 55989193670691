import React from 'react';
import { Localization, useNotification as ConnexUseNotification } from 'connex-cds';

/**
 * Custom hook for providing notification functionality.
 *
 * This hook provides access to notification function from the Connex CDS library.
 *
 * @returns {object} An object containing functions for triggering success and error notifications.
 *
 * @property {Function} triggerSuccessNotification - Function to trigger a success notification.
 * @property {Function} triggerErrorNotification - Function to trigger an error notification.
 */
export const useNotification = () => {
  const translateMessage = Localization.useTranslateMessage();
  const notification = ConnexUseNotification();

  /**
   * Trigger a success notification with a translated message.
   *
   * @param {string} token - The token used for translation.
   * @returns {void}
   */
  const sendSuccessNotification = React.useCallback(
    token => {
      notification.success(translateMessage(token));
    },
    [notification, translateMessage]
  );

  /**
   * Trigger an error notification with a translated message.
   *
   * @param {string} token - The token used for translation.
   * @returns {void}
   */
  const sendErrorNotification = React.useCallback(
    token => {
      notification.error(translateMessage(token));
    },
    [notification, translateMessage]
  );

  return { sendSuccessNotification, sendErrorNotification };
};
