import { useCallback, useState } from 'react';
import { Providers } from '../../providers';
import dayjs from 'dayjs';
import { CORE_ENTITY } from 'core';

export const useDateSelector = () => {
  const [dateValue, setDateValue] = useState(null);
  const { refreshOverviewData, setDateOption, locationTimeZone } = Providers.useInvOverviewDataContext();
  const [datePicker, setDatePicker] = useState(
    dayjs(CORE_ENTITY.Utils.dateTimeZone(new Date(), locationTimeZone.current))
  );

  const refreshInvOverviewData = useCallback(() => {
    refreshOverviewData();
  }, [refreshOverviewData]);

  const onChangeDate = dates => {
    const startDateTimestamp = CORE_ENTITY.Utils.dateTimeZone(new Date(dates.$d), locationTimeZone.current).setHours(
      0,
      0,
      0,
      0
    );
    const startDate = new Date(startDateTimestamp).toISOString().replace(/.\d+Z$/g, 'Z');

    const endDateTimestamp = CORE_ENTITY.Utils.dateTimeZone(new Date(dates.$d), locationTimeZone.current).setHours(
      23,
      59,
      59,
      999
    );
    const endDate = new Date(endDateTimestamp).toISOString().replace(/.\d+Z$/g, 'Z');

    setDatePicker(dates);
    setDateOption({ startDate, endDate });
  };

  const handleCtaArrows = (direction, date) => {
    if (direction === 'left') {
      const backDay = date.add(-1, 'day');
      setDatePicker(backDay);
      onChangeDate(backDay);
    } else if (direction === 'right') {
      const nextDay = date.add(1, 'day');
      onChangeDate(nextDay);
      setDatePicker(nextDay);
    }
  };

  return {
    refreshInvOverviewData,
    onChangeDate,
    dateValue,
    setDateValue,
    handleCtaArrows,
    datePicker,
  };
};
