import React from 'react';
import { SelectorMenu } from 'for-sdk/common';
import FilterButton from './FilterButton';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FiltersContainerStyles } from './style';
import { useFiltersHelper } from './Filter.helper';

const Styled = styled.div`
  ${FiltersContainerStyles}
`;

export const Filters = ({ filters, disabled, source }) => {
  const {
    locationSelectorOptions,
    materialSelectorOptions,
    typeSelectorOptions,
    vendorSelectorOptions,
    receiptStatusSelectorOptions,
    locationFilterValue,
    materialFilterValue,
    typeFilterValue,
    vendorFilterValue,
    receiptStatusFilterValue,
    handleChangeLocation,
    handleChangeMaterial,
    handleChangeType,
    handleChangeVendor,
    handleChangeReceiptStatus,
  } = useFiltersHelper({ source });

  return (
    <Styled>
      {filters.enableLocation && (
        <SelectorMenu
          value={locationFilterValue}
          disabled={disabled}
          options={locationSelectorOptions}
          onChange={handleChangeLocation}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton type="locationCity" isDropdown isActive={false} isPressed={isOpen} {...props} />
          )}
        />
      )}
      {filters.enableProduct && (
        <SelectorMenu
          value={materialFilterValue}
          disabled={disabled}
          options={materialSelectorOptions}
          onChange={handleChangeMaterial}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton type="product" isDropdown isActive={false} isPressed={isOpen} {...props} />
          )}
        />
      )}
      {filters.enableType && (
        <SelectorMenu
          value={typeFilterValue}
          disabled={disabled}
          options={typeSelectorOptions}
          onChange={handleChangeType}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton type="tune" isDropdown isActive={false} isPressed={isOpen} {...props} />
          )}
        />
      )}
      {filters.enableVendor && (
        <SelectorMenu
          value={vendorFilterValue}
          disabled={disabled}
          options={vendorSelectorOptions}
          onChange={handleChangeVendor}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton type="monetization" isDropdown isActive={false} isPressed={isOpen} {...props} />
          )}
        />
      )}
      {filters.enableReceiptStatus && (
        <SelectorMenu
          value={receiptStatusFilterValue}
          disabled={disabled}
          options={receiptStatusSelectorOptions}
          onChange={handleChangeReceiptStatus}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton type="receiptStatus" isDropdown isActive={false} isPressed={isOpen} {...props} />
          )}
        />
      )}
    </Styled>
  );
};

Filters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  source: PropTypes.string,
};
