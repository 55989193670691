export const component = {
  invTrans: {
    filter: {
      vendor: {
        title: 'Vendor',
        option: {
          internal: 'Internal',
        },
      },
    },
    gridHeaderMenu: {
      menu_label: 'Menu',
      unsort_label: 'Unsort',
      asc_label: 'Sort by ASC',
      desc_label: 'Sort by DESC',
      sort_label: 'Sort',
    },
    gridFooter: {
      count: 'Showing',
      countSingle: 'record',
      countPlural: 'records',
      selectionSingle: 'record selected',
      selectionPlural: 'records selected',
    },
  },
};
