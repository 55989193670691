import { css } from '@emotion/react';

export const ProductRowStyles = ({ theme }) => css`
  .product-content {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 64px;
    align-items: center;
    padding: 0px 15px;
    width: 100%;
  }

  .product-title {
    cursor: pointer;
    position: relative;

    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }

    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: uppercase;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[95] })};
    }

    .danger {
      color: ${theme.colorsCA.red[50]};
    }

    .warning {
      color: ${theme.colorsCA.yellow[10]};
    }

    .over {
      color: #6750a4;
    }
  }

  .product-stock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[95] })};
    p {
      margin: 0;
    }
  }

  .product-cta {
    justify-self: center;

    .routeBtn {
      background-color: ${theme.colorsCA.darkBlue[50]};
      color: ${theme.colorsCA.neutral[100]};
      font-size: 14px;
      line-height: 20px;
      border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[50] })};
    }

    .routeBtn:disabled {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[60] })};
      background-color: ${theme.colorsCA.neutral[10]}1F;
    }
  }

  .product-graph {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
      position: absolute;
      top: 5px;
      right: 15px;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[100] })};
      animation: customLoader 1s linear infinite;
    }

    @keyframes customLoader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
