export const entity = {
  invTrans: {
    name: 'Inventorio de Transacciones',
    material: 'Material',
    location: 'Ubicación',
    type: 'Tipo',
    quantity: 'Cantidad',
    ticketCode: 'Código Ticket',
    supplier: 'Proveedor',
    hauler: 'Transportista',
    vehicle: 'Vehículo',
    poNumber: 'Número PO',
    created: 'Fecha',
    unit: 'Unidad',
    ticket: 'Tiquete',
    vendor: 'Vendedor',
    receiptStatus: 'Estado Recibo',
    adjustmentType: 'Tipo de Ajuste',
    status: 'Estado',
    stock_take: 'Toma de existencias',
    quantity_adjustment: 'Ajuste de cantidad',
    bins: 'Contenedor / Silo',
  },
};
