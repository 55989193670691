import React, { useState } from 'react';
import { CORE_ENTITY } from 'core';
import { INVOVERVIEW_ENTITY } from 'entities/inventory-overview';
import { Components } from '../../components';
import { Modal, Button } from 'for-sdk/common';
import cn from 'classnames';
import PropTypes from 'prop-types';

export const CreateReceipt = ({ enRouteReceipts = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { refreshOverviewData } = INVOVERVIEW_ENTITY.Providers.useInvOverviewDataContext();

  const onClickHandleModal = event => {
    if (event.mode === 'add' && event.completed) {
      refreshOverviewData();
    }

    setShowModal(!showModal);
  };

  return (
    <>
      <Button className={cn('routeBtn')} onClick={onClickHandleModal} disabled={enRouteReceipts.length === 0}>
        {enRouteReceipts.length} {translateMessage('view.receipt.enRoute')}
      </Button>
      <Modal
        open={showModal}
        title={translateMessage('view.receipt.create')}
        handleClose={onClickHandleModal}
        modalContent={
          <Components.Carousel>
            {enRouteReceipts?.map((receipt, index) => (
              <Components.ReceiptForm
                key={receipt.crn}
                idRecord=""
                mode="create"
                recordData={{ ...receipt, totalStep: enRouteReceipts.length, currentStep: index + 1 }}
                onSubmitCallback={onClickHandleModal}
              />
            ))}
          </Components.Carousel>
        }
      />
    </>
  );
};

CreateReceipt.propTypes = {
  enRouteReceipts: PropTypes.array,
};
