import { useMemo } from 'react';
import { CORE_ENTITY } from 'core';
import { Components } from '../../components';

export const useTableHelper = () => {
  //TODO: call transactions service with the default location from dashboard

  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { transactions, isLoading } = Components.useTransactionsDataContext();

  const localeText = useMemo(
    () => ({
      columnMenuLabel: translateMessage('component.invTrans.gridHeaderMenu.menu_label'),
      columnMenuUnsort: translateMessage('component.invTrans.gridHeaderMenu.unsort_label'),
      columnMenuSortAsc: translateMessage('component.invTrans.gridHeaderMenu.asc_label'),
      columnMenuSortDesc: translateMessage('component.invTrans.gridHeaderMenu.desc_label'),
      columnHeaderSortIconLabel: translateMessage('component.invTrans.gridHeaderMenu.sort_label'),
    }),
    [translateMessage]
  );

  const footerProps = useMemo(() => ({ totalCount: transactions?.length || 0 }), [transactions?.length]);

  return {
    translateMessage,
    localeText,
    footerProps,
    transactions,
    isLoading,
  };
};
