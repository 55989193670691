import { css } from '@emotion/react';

export const CarouselStyles = ({ theme }) => css`
  .react-multiple-carousel__arrow {
    background: ${theme.colorsCA.darkBlue[50]};
    margin-bottom: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`;
