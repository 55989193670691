import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectorMenu from '../../common/SelectorMenu';
import Icon from '../../common/Icon';

const selectorOptions = [
  {
    value: 'Yesterday',
    translation: 'general.time_period_option.yesterday',
  },
  {
    value: 'Today',
    translation: 'general.time_period_option.today',
  },
  {
    value: 'This_Week',
    translation: 'general.time_period_option.this_week',
  },
  {
    value: 'This_Month',
    translation: 'general.time_period_option.this_month',
  },
  {
    value: 'Last_Month',
    translation: 'general.time_period_option.last_month',
  },
  {
    value: 'Custom',
    translation: 'general.time_period_option.custom_dates',
  },
];

const DateSelector = ({ disabled, handleShowCalendar, onDateFilterTypeChange }) => {
  const [value, setValue] = useState(selectorOptions[1].value);

  const handleChange = option => {
    setValue(option.value);
    handleShowCalendar(option.value === 'Custom');
    onDateFilterTypeChange(prevDate => (option.value === 'Custom' ? prevDate : option.value));
  };

  return (
    <SelectorMenu
      value={value}
      disabled={disabled}
      labelStartIcon={<Icon type="calendar" />}
      options={selectorOptions}
      onChange={handleChange}
    />
  );
};

DateSelector.propTypes = {
  disabled: PropTypes.bool,
  handleShowCalendar: PropTypes.func,
  onDateFilterTypeChange: PropTypes.func,
};

export default DateSelector;
