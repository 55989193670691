import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InvOverviewDataContext } from '.';
import { useApiHook } from '../../api';
import { LOCATION_ENTITY } from 'entities/location';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { CORE_ENTITY } from 'core';

export const InvOverviewDataProvider = ({ children }) => {
  const { data, isLoading: isLocationLoading } = LOCATION_ENTITY.appLogic().List({
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  });
  const { mutateAsync: getTransactions } = INVTRANS_ENTITY.appLogic().List;
  const [invOverviewData, setInvOverviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTrans, setIsLoadingTrans] = useState(true);
  const [locations, setLocations] = useState([]);
  const [running, setRunning] = useState(true);
  const [dateOption, setDateOption] = useState({ dateOption: 'Today' });
  const [showChartDemand, setShowChartDemand] = useState(true);
  const { mutateAsync: getInventoryOverview } = useApiHook().ListMutation;
  const allLocation = useRef([]);
  const selectedProduct = useRef('');
  const selectedLocation = useRef('');
  const locationTimeZone = useRef('America/Chicago');

  useEffect(() => {
    if (data && data.length !== 0 && running) {
      setLocations(data);
      setRunning(false);
      allLocation.current = data;
    }
  }, [data, running]);

  const validateDateOption = useCallback(() => {
    setShowChartDemand(false);

    if (dateOption?.dateOption === 'Today') {
      setShowChartDemand(true);
    } else {
      const currentDate = new Date().setHours(0, 0, 0);
      const selectedDate = new Date(dateOption.startDate);

      if (CORE_ENTITY.Utils.compareDates(selectedDate, currentDate) !== 'LOWER') {
        setShowChartDemand(true);
      }
    }
  }, [dateOption]);

  const ListInvOverviewByLocation = useCallback(
    async (filters = { locationRef: '', productRef: '', dateOption }) => {
      try {
        setIsLoading(true);
        const { locationRef, productRef } = filters;
        selectedLocation.current = locationRef;
        const response = await getInventoryOverview({
          queryParams: {
            filters: {
              locationRef: locationRef,
              productRef: productRef,
              ...dateOption,
            },
          },
        });

        setInvOverviewData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [getInventoryOverview, dateOption]
  );

  const handleChangeFilters = useCallback(
    async filters => {
      validateDateOption();

      if (filters.locationRef !== '' && filters.locationRef !== 'All') {
        setLocations(allLocation.current.filter(el => el.value === filters.locationRef));
      } else {
        setLocations(allLocation.current);
      }

      if (filters.productRef !== '') {
        selectedProduct.current = filters.productRef;
        ListInvOverviewByLocation({
          locationRef:
            filters.locationRef !== '' && filters.locationRef !== 'All'
              ? filters.locationRef
              : selectedLocation.current,
          productRef: filters.productRef,
          dateOption,
        });
      }

      if (selectedLocation.current !== '' && filters.productRef === '') {
        ListInvOverviewByLocation({
          locationRef: selectedLocation.current,
          productRef: 'All',
          dateOption,
        });
      }
    },
    [setLocations, ListInvOverviewByLocation, dateOption, validateDateOption]
  );

  const refreshOverviewData = useCallback(() => {
    if (selectedLocation.current !== '') {
      ListInvOverviewByLocation({
        locationRef: selectedLocation.current,
        productRef: selectedProduct.current !== '' ? selectedProduct.current : 'All',
        dateOption,
      });
    }
  }, [ListInvOverviewByLocation, dateOption]);

  const listInvTransactions = useCallback(
    async productRef => {
      try {
        setIsLoadingTrans(true);
        if (productRef !== '' && selectedLocation.current !== '') {
          const params = {
            locationRef: selectedLocation.current,
            productRef,
            queryParams: { ...dateOption, convertUom: CORE_ENTITY.Constants.UomIsoCodes.tons },
          };
          const data = await getTransactions({ args: params });
          const sortData = CORE_ENTITY.Utils.sortByKey(data, 'transDateTime');
          setIsLoadingTrans(false);
          return sortData;
        }
      } catch (e) {
        setIsLoadingTrans(false);
        return [];
      }
    },
    [dateOption, getTransactions]
  );

  const value = useMemo(() => {
    return {
      invOverviewData,
      ListInvOverviewByLocation,
      handleChangeFilters,
      isLoading,
      isLocationLoading,
      locations,
      selectedProduct,
      refreshOverviewData,
      setDateOption,
      dateOption,
      listInvTransactions,
      isLoadingTrans,
      showChartDemand,
      locationTimeZone,
    };
  }, [
    invOverviewData,
    ListInvOverviewByLocation,
    handleChangeFilters,
    isLoading,
    isLocationLoading,
    locations,
    selectedProduct,
    refreshOverviewData,
    setDateOption,
    dateOption,
    listInvTransactions,
    isLoadingTrans,
    showChartDemand,
    locationTimeZone,
  ]);

  return <InvOverviewDataContext.Provider value={value}>{children}</InvOverviewDataContext.Provider>;
};
