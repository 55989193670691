import React from 'react';
import { Stack, Divider, Accordion, Icon } from 'for-sdk/common';
import { ProductRowStyles } from './style';
import { RECEIPT_ENTITY } from 'entities/receipt';
import styled from '@emotion/styled';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useProductRow } from './ProductRow.helper';
import { DemandGraph } from '../invOverviewEntity.components';

const Styled = styled.div`
  ${ProductRowStyles}
`;

const ProductRow = ({ productData }) => {
  const {
    applyStockColor,
    productName,
    stockLevel,
    onHandQuantity,
    maxStockQuantity,
    safestyStockQuantity,
    reorderStockQuantity,
    enRouteReceipts,
    supplyQuantity,
    demandQuantity,
    showProductGraph,
    productRef,
    isExpanded,
    isLoadingTrans,
    graphData,
    axisRange,
  } = useProductRow({ productData });

  return (
    <Styled>
      <Stack className={cn('product-content')}>
        <Stack className={cn('product-title')} onClick={() => showProductGraph(productData.crn)}>
          <span className={cn('stock-lvl', applyStockColor())}>{stockLevel}</span>
          <h2>{productName}</h2>
        </Stack>
        <Stack className={cn('product-stock')} onClick={() => showProductGraph(productData.crn)}>
          <p>{`On Hand ${onHandQuantity.totalQuantity}/${maxStockQuantity}T`}</p>
          {onHandQuantity.silos.length > 1 && (
            <p>{`, (${onHandQuantity.silos.map(silo => `${silo.name}: ${silo.quantity}T`)})`}</p>
          )}
          <p>{`, Demand ${demandQuantity}T, Supply: ${supplyQuantity}T`}</p>
        </Stack>
        <Stack className={cn('product-cta')}>
          <RECEIPT_ENTITY.Views.CreateReceipt enRouteReceipts={enRouteReceipts} />
        </Stack>
      </Stack>
      {isExpanded && <Divider />}
      <Accordion
        header={<></>}
        styletheme="second"
        content={
          <div className={cn('product-graph')}>
            {isLoadingTrans && <Icon type="loader" />}
            <DemandGraph
              axisRange={axisRange}
              maxStock={maxStockQuantity}
              safetyStock={safestyStockQuantity}
              onHandStock={onHandQuantity.totalQuantity}
              graphDataSet={graphData}
              reorderStock={reorderStockQuantity}
            />
          </div>
        }
        isExpanded={productData.crn === productRef}
      />
      {isExpanded && <Divider />}
    </Styled>
  );
};

ProductRow.propTypes = {
  productData: PropTypes.object,
};

export default ProductRow;
