import React from 'react';
import styled from '@emotion/styled';
import { ContainerStyles } from './styles';
import { Stack } from 'for-sdk/common';
import { useListInventoryTransactionsHelper } from './ListInventoryTransactions.helper';
import { CORE_ENTITY } from 'core';
import { Components } from '../../components';
import cn from 'classnames';

const ContainerStyled = styled.div`
  ${ContainerStyles}
`;

export const ListInventoryTransactions = () => {
  const { filters } = useListInventoryTransactionsHelper();

  return (
    <>
      <Components.TransactionDataProvider>
        <ContainerStyled>
          <Stack className={cn('search-content')}>
            <Components.SearchFilter disabled={false} />
          </Stack>
          <Components.DateSelectorFilter />
        </ContainerStyled>
        <CORE_ENTITY.Components.Filters filters={filters} disabled={false} source="inventoryTransaction" />
        <Components.Table />
      </Components.TransactionDataProvider>
    </>
  );
};
