export const entity = {
  receipt: {
    name: 'Receipt',
    material: 'Material',
    location: 'Location',
    quantity: 'Quantity',
    vendor: 'Vendor',
    hauler: 'Hauler',
    vehicle: 'Vehicle',
    ticket: 'Ticket',
    unit: 'Unit',
    date: 'Date',
    time: 'Time',
    purchaseOrder: 'Purchase Order',
    receiptStatus: 'Receipt Status',
    devices: 'Devices',
    bins: 'Bin / Silo',
  },
};
