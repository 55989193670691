import React from 'react';
import styled from '@emotion/styled';
import { ModalStyles } from './style';
import cn from 'classnames';
import { Modal, Stack } from '@mui/material';
import { Icon, Divider } from '..';
import PropTypes from 'prop-types';

const Styled = styled(Modal)`
  ${ModalStyles}
`;

const CustomModal = ({ title, open, handleClose, modalContent }) => {
  return (
    <Styled open={open} onClose={handleClose}>
      <Stack className={cn('modal-content')}>
        <Stack className={cn('modal-header')}>
          <h2>{title}</h2>
          <Icon onClick={handleClose} className="close-icon" type="closeOutlined" />
        </Stack>
        <Divider />
        <Stack className={cn('modal-content-wrapper')}>{modalContent}</Stack>
      </Stack>
    </Styled>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  modalContent: PropTypes.any,
};

export default CustomModal;
