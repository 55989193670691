import { INVOVERVIEW_ENTITY } from 'entities/inventory-overview';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { RECEIPT_ENTITY } from 'entities/receipt';
import { ADJUSTMENT_ENTITY } from 'entities/adjustment';
import { USAGE_ENTITY } from 'entities/usage';

export const entity = {
  ...INVTRANS_ENTITY.i18n['en-US'].entity,
  ...RECEIPT_ENTITY.i18n['en-US'].entity,
  ...ADJUSTMENT_ENTITY.i18n['en-US'].entity,
  ...USAGE_ENTITY.i18n['en-US'].entity,
  ...INVOVERVIEW_ENTITY.i18n['en-US'].entity,
};
