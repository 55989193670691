import { css } from '@emotion/react';

export const GridFooterStyles = ({ theme }) => css`
  grid-area: footer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  gap: 22px;
  border-top: 1px solid ${theme.colorsCA.neutral[90]};

  .steps-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;
