import { Localization } from 'connex-cds';
import TableActions from './TableActions';

export const initialState = {
  sorting: {
    sortModel: [{ field: 'transDateTime', sort: 'desc' }],
  },
};

export const classes = {
  row: 'main-grid-row',
};

export const columns = translateMessage => [
  {
    field: 'inventoryProductDescription',
    flex: 1,
    headerName: translateMessage('entity.invTrans.material'),
    width: 240,
  },
  {
    field: 'locationName',
    flex: 1,
    headerName: translateMessage('entity.invTrans.location'),
    width: 160,
  },
  {
    field: 'type',
    flex: 1,
    headerName: translateMessage('entity.invTrans.type'),
    width: 160,
    renderCell: params => {
      return <div>{params?.value ? params.value.toLowerCase() : ''}</div>;
    },
  },
  {
    field: 'quantity',
    flex: 1,
    headerName: translateMessage('entity.invTrans.quantity'),
    width: 160,
    renderCell: params => {
      return (
        <div>
          {params?.row?.adjustmentType === 'STOCK_TAKE' ? params.row?.stockTakeQuantity : params.value?.value}
          {params?.row?.adjustmentType === 'STOCK_TAKE'
            ? params.row?.stockTakeQuantityUomCode === 'STN'
              ? 'T'
              : params.row?.stockTakeQuantityUomCode
            : params.value?.uomCode === 'STN'
            ? 'T'
            : params.value?.uomCode}
        </div>
      );
    },
  },
  {
    field: 'inventoryContainer',
    flex: 1,
    headerName: translateMessage('entity.invTrans.bins'),
    width: 240,
  },
  {
    field: 'ticketId',
    flex: 1,
    headerName: translateMessage('entity.invTrans.ticketCode'),
    width: 160,
  },
  {
    field: 'supplierName',
    flex: 1,
    headerName: translateMessage('entity.invTrans.vendor'),
    width: 160,
  },
  {
    field: 'adjustmentType',
    flex: 1,
    headerName: translateMessage('entity.invTrans.adjustmentType'),
    width: 160,
    renderCell: params => {
      return params.value ? (
        <div>{translateMessage(`entity.invTrans.${params?.value ? params.value.toLowerCase() : ''}`)}</div>
      ) : (
        ''
      );
    },
  },
  {
    field: 'receiptStatus',
    flex: 1,
    headerName: translateMessage('entity.invTrans.receiptStatus'),
    width: 160,
    renderCell: params => {
      return params.value ? (
        <div>{translateMessage(`info.receipt.${params?.value ? params.value.toLowerCase() : ''}`)}</div>
      ) : (
        ''
      );
    },
  },
  {
    field: 'transDateTime',
    flex: 1,
    headerName: translateMessage('entity.invTrans.created'),
    width: 240,
    renderCell: params => {
      return <Localization.ShortDateTime date={new Date(params.value)} />;
    },
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 30,
    getActions: params => {
      return [<TableActions rowData={params?.row} />];
    },
  },
];
