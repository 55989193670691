import { QueryClientProvider } from '@tanstack/react-query';
import { useTanStackClient } from './QueryClient';

/**
 * TanStackProvider is a wrapper component that provides the necessary context
 * and configuration for using the TanStack React Query library in your
 * application.
 *
 * The component renders the React Query's `QueryClientProvider` and it should
 * be placed at the root of your application to enable the usage of React Query
 * features.
 *
 * @param props - The child elements to be wrapped by the TanStackProvider.
 *
 * @returns A React functional component that wraps the provided children with
 * React Query's context and enables the React Query Devtools for development
 * purposes.
 *
 * @example
 * ```js
 * import { TanStackProvider } from './path/to/TanStackProvider';
 *
 * const App = () => {
 *   return (
 *     <TanStackProvider>
 *       Your component tree
 *     </TanStackProvider>
 *   );
 * };
 * ```
 *
 * @see [OfficialDocumentation - QueryClietProvider](https://tanstack.com/query/v4/docs/react/reference/QueryClientProvider)
 */
export const TanStackProvider = props => {
  const client = useTanStackClient();

  return <QueryClientProvider client={client}>{props.children}</QueryClientProvider>;
};
