import React, { useState } from 'react';
import { CORE_ENTITY } from 'core';
import { AdjustmentForm } from '../../components/adjustment-form';
import { Modal, Button } from 'for-sdk/common';
import cn from 'classnames';

export const CreateAdjustment = () => {
  const [showModal, setShowModal] = useState(false);
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();

  const onClickHandleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Button className={cn('adjustment-button')} onClick={onClickHandleModal}>
        {translateMessage('view.adjustment.create')}
      </Button>
      <Modal
        open={showModal}
        title={translateMessage('view.adjustment.create')}
        handleClose={onClickHandleModal}
        modalContent={<AdjustmentForm idRecord="" mode="create" />}
      />
    </>
  );
};
