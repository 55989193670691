import { QueryClient } from '@tanstack/react-query';

/**
 * A custom hook that returns a new instance of `QueryClient`.
 *
 * The `QueryClient` is the core object used to interact with the React Query
 * library. It manages the caching, fetching, and invalidation of queries.
 *
 * @remarks
 * A new instance of `QueryClient` must be provided to the QueryClientProvider
 * to enable the usage of React Query features in your app.
 *
 * @example
 * ```js
 * import { QueryClientProvider } from '@tanstack/react-query';
 * import { useQueryClient } from './path/to/useQueryClient';
 *
 * const MyComponent = () => {
 *   const client = useQueryClient();
 *
 *   return (
 *      <QueryClientProvider client={client}>
 *         Your code goes here
 *      </QueryClientProvider>
 *   );
 * };
 * ```
 * @see [OfficialDocumentation - QueryClient](https://tanstack.com/query/v4/docs/react/reference/QueryClient)
 */
export const useTanStackClient = () => {
  const ONE_HOUR = 1000 * 60 * 60;
  const TWELVE_HOURS = ONE_HOUR * 12;

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5000,
        cacheTime: TWELVE_HOURS,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
      },
    },
  });

  return client;
};
