import da from 'dayjs/locale/da';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import en_gb from 'dayjs/locale/en-gb';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import nl from 'dayjs/locale/nl';
import sv from 'dayjs/locale/sv';

export default {
  languages: {
    'en-US': 'English (US)',
    de: 'Deutsch',
    es: 'Español',
  },
};

export const supportedLocales = {
  da: da,
  de: de,
  'en-US': en,
  'en-GB': en_gb,
  'es-CO': es,
  'fr-FR': fr,
  'nl-NL': nl,
  'sv-SE': sv,
};
