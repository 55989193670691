import React from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';
import { Formik, Form, Field } from 'formik';
import { Button, SelectField, DateField, TimeField, Stack, TextField, Icon } from 'for-sdk/common';
import { CORE_ENTITY } from 'core';
import { useFormContentHelper } from './AdjustmentForm.helper';
import { StockStyles } from './style';
import PropTypes from 'prop-types';

const InventoryStockSection = styled(Stack)`
  ${StockStyles};
`;

export const AdjustmentForm = ({ mode, recordData, onSubmitCallback }) => {
  const {
    initialValues,
    validationSchema,
    translateMessage,
    isDisabledByAdjType,
    disabledFieldsByAdjType,
    onSubmit,
    locationsOptions,
    productsOptions,
    binsOptions,
    uomOptions,
    setLocationHelperTxt,
    setProductsHelperTxt,
    isLoading,
    isLoadingTrans,
  } = useFormContentHelper({
    formMode: mode,
    recordData,
    onSubmitCallback,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values, setValues }) => (
        <Form>
          <CORE_ENTITY.Components.GridFormContainer>
            <CORE_ENTITY.Components.GridFormContent>
              {isLoadingTrans && <Icon type="loader" className={cn('loaderIcon')} />}
              <Field name="location">
                {props => (
                  <SelectField
                    fieldprops={props}
                    label={translateMessage('entity.adjustment.location')}
                    options={locationsOptions}
                    helperText={setLocationHelperTxt}
                    disabled={isLoading}
                  />
                )}
              </Field>
              <Field name="material">
                {props => (
                  <SelectField
                    fieldprops={props}
                    label={translateMessage('entity.adjustment.material')}
                    options={productsOptions}
                    helperText={setProductsHelperTxt}
                    disabled={isLoading}
                  />
                )}
              </Field>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                <Field name="date">
                  {props => (
                    <DateField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.date')}
                      disabled={isDisabledByAdjType || isLoading}
                    />
                  )}
                </Field>
                <Field name="time">
                  {props => (
                    <TimeField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.time')}
                      disabled={isDisabledByAdjType || isLoading}
                    />
                  )}
                </Field>
              </Stack>
              {binsOptions.length !== 0 && (
                <Field name="bins">
                  {props => (
                    <SelectField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.bins')}
                      options={binsOptions}
                      disabled={isLoading}
                    />
                  )}
                </Field>
              )}
              <Field name="adjustmentType">
                {props => (
                  <SelectField
                    fieldprops={props}
                    label={translateMessage('entity.adjustment.type')}
                    options={[
                      { value: 'stock_take', label: 'Stock Take' },
                      { value: 'quantity_adjustment', label: 'Adjustment Quantity' },
                    ]}
                    onChange={value => {
                      disabledFieldsByAdjType(value.target.value, setValues, values);
                    }}
                    disabled={isLoading}
                  />
                )}
              </Field>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                <Field name="quantity">
                  {props => (
                    <TextField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.quantity')}
                      type="number"
                      disabled={isLoading}
                    />
                  )}
                </Field>
                <Field name="unit">
                  {props => (
                    <SelectField
                      fieldprops={props}
                      label={translateMessage('entity.adjustment.unit')}
                      options={uomOptions}
                      disabled={isLoading}
                    />
                  )}
                </Field>
              </Stack>
              <InventoryStockSection>
                <p>
                  {translateMessage('entity.adjustment.currentOnHand')} <span>150 tn</span>
                </p>
              </InventoryStockSection>
            </CORE_ENTITY.Components.GridFormContent>
            <CORE_ENTITY.Components.GridFormFooter
              withDeleteAction={{
                enabled: mode === 'edit',
                isDisabled: isLoading,
                onCloseHandler: () => {
                  console.log('onCloseHandler');
                },
                onConfirmHandler: () => {
                  console.log('onConfirmHandler');
                },
                strings: {
                  dialogTitle: translateMessage('view.adjustment.delete'),
                  deleteDialogMsg: translateMessage('info.adjustment.deleteMsg'),
                  deleteCtaLabel: translateMessage('view.adjustment.delete'),
                },
              }}
            >
              <Button
                className={cn('save-button')}
                type="submit"
                disabled={mode === 'create' ? !isValid || !dirty || isLoading : !isValid || isLoading}
                variant="text"
              >
                {translateMessage('general.actions.save')}
              </Button>
            </CORE_ENTITY.Components.GridFormFooter>
          </CORE_ENTITY.Components.GridFormContainer>
        </Form>
      )}
    </Formik>
  );
};

AdjustmentForm.propTypes = {
  mode: PropTypes.string,
  recordData: PropTypes.object,
  onSubmitCallback: PropTypes.func,
};
