import { entity } from './entity';
import { info } from './info';
import { validation } from './validation';
import { view } from './view';

export default {
  entity,
  info,
  validation,
  view,
};
