import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Accordion, Loader } from 'for-sdk/common';
import { Components } from '..';
import { Providers } from '../../providers';
import { CORE_ENTITY } from 'core';
import { CollapsibleStyles } from './styles';
import cn from 'classnames';

const Styled = styled.div`
  ${CollapsibleStyles};
`;

const CollapsibleGroup = () => {
  const {
    invOverviewData,
    ListInvOverviewByLocation,
    isLoading: loadingOverview,
    locations,
    isLocationLoading,
    selectedProduct,
    locationTimeZone,
  } = Providers.useInvOverviewDataContext();
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const [locationRef, setLocationRef] = useState('');

  const listOverviewProducts = (event, expanded, item) => {
    if (expanded) {
      setLocationRef(item.value);
      locationTimeZone.current = item?.timeZone || 'America/Chicago';
      ListInvOverviewByLocation({
        locationRef: item.value,
        productRef: selectedProduct.current !== '' ? selectedProduct.current : 'All',
      });
    } else {
      setLocationRef('');
    }
  };

  return (
    <Styled>
      {isLocationLoading ? (
        <Loader />
      ) : (
        locations.map(el => (
          <Accordion
            key={el.value}
            header={<h2>{el.label}</h2>}
            styletheme="main"
            onChangeExpanded={(event, expanded) => listOverviewProducts(event, expanded, el)}
            content={
              !loadingOverview ? (
                invOverviewData.length !== 0 ? (
                  invOverviewData[0].products.map(product => (
                    <Components.ProductRow key={product.crn} productData={product} />
                  ))
                ) : (
                  <p className={cn('noProducts')}>{translateMessage('info.product.noRecords')}</p>
                )
              ) : (
                <Components.CollapsibleSkeleton rows={5} />
              )
            }
            enableExpandIcon={true}
            isExpanded={el.value === locationRef}
          />
        ))
      )}
    </Styled>
  );
};

export default CollapsibleGroup;
