import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { initialState, columns, classes } from './tableConfig';
import TableFooter from './TableFooter';
import NoRowsData from './NoRowsData';
import { DataGridTableStyles } from './style';
import styled from '@emotion/styled';
import { useTableHelper } from './Table.helper';
import { TableSkeleton } from './components';

const Styled = styled(DataGridPro)`
  ${DataGridTableStyles}
`;

export const Table = () => {
  const { translateMessage, localeText, footerProps, transactions, isLoading } = useTableHelper();

  return (
    <Styled
      initialState={initialState}
      localeText={localeText}
      columnHeaderHeight={59}
      columnBuffer={1}
      columnThreshold={1}
      columns={columns(translateMessage)}
      checkboxSelection={false}
      classes={classes}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableColumnResize
      disableColumnReorder
      disableColumnFilter
      disableColumnSelector
      disableColumnPinning
      disableDensitySelector
      rows={transactions}
      getRowId={row => row.crn}
      rowHeight={74}
      rowBuffer={3}
      rowThreshold={3}
      slots={{
        noRowsOverlay: NoRowsData,
        footer: TableFooter,
        loadingOverlay: TableSkeleton,
      }}
      slotProps={{
        footer: footerProps,
        loadingOverlay: { rowHeight: 54 },
      }}
      loading={isLoading}
    />
  );
};
