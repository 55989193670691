export const component = {
  invTrans: {
    filter: {
      vendor: {
        title: 'Vendedor',
        option: {
          internal: 'Interno',
        },
      },
    },
    gridHeaderMenu: {
      menu_label: 'Menú',
      unsort_label: 'Desordenar',
      asc_label: 'Ordenar por ASC',
      desc_label: 'Ordenar por DESC',
      sort_label: 'Ordenar',
    },
    gridFooter: {
      count: 'Mostrando',
      countSingle: 'registro',
      countPlural: 'registros',
      selectionSingle: 'registro seleccionado',
      selectionPlural: 'registros seleccionados',
    },
  },
};
