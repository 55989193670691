import React from 'react';
import { Button, Icon } from 'for-sdk/common';
import { useDateSelector } from './DateSelectorFilter.helper';
import { Components } from '..';
import cn from 'classnames';

const DateSelectorFilter = () => {
  const { refreshInvOverviewData, onChangeDate, handleCtaArrows, datePicker } = useDateSelector();
  return (
    <>
      <Components.DatePerDayPicker
        dateValue={datePicker}
        onChangeHandler={onChangeDate}
        handleArrows={handleCtaArrows}
      />
      <Button className={cn('refresh-button')} onClick={refreshInvOverviewData}>
        <Icon type="refresh" />
      </Button>
    </>
  );
};

export default DateSelectorFilter;
