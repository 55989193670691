import { css } from '@emotion/react';

export const ContainerStyles = ({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: initial !important;
  margin-bottom: 20px;
  position: relative;
  padding: 0px 20px 0px 10px;

  .search-content {
    width: 400px;

    .icon {
      font-size: 24px;
      color: ${theme.colorsCA.neutral[40]};
    }
  }

  .ctas-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .export-action-button {
    border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};
    background-color: ${theme.validateMode({ light: theme.colorsCA.neutral[100] })};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};
    margin-right: 10px;
    font-weight: 500;

    gap: 10px;

    &:hover {
      border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};
      background-color: ${theme.validateMode({ light: theme.colorsCA.neutral[100] })};
    }
  }

  .filter-icon-button {
    font-size: 43px;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};
    margin-left: 10px;
    cursor: pointer;
  }

  .icon {
    font-size: 18px;
  }

  .refresh-button {
    margin-left: 16px;
    background-color: ${theme.colorsCA.blue[95]};
    border: none;
    min-width: 48px;
    padding: 0px;
    border-radius: 100%;
    height: 48px;

    .icon {
      color: ${theme.colorsCA.darkBlue[10]};
      font-size: 24px;
    }
  }
`;
