import { useEffect, useState } from 'react';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { CORE_ENTITY } from 'core';
import { LOCATION_ENTITY } from 'entities/location';
import { PRODUCT_ENTITY } from 'entities/product';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { ITEMS_ENTITY } from 'entities/unit-of-measure';

export const useFormContentHelper = ({ formMode, recordData, onSubmitCallback }) => {
  const locationsOptions = LOCATION_ENTITY.appLogic().List({
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;
  const productsOptions = PRODUCT_ENTITY.appLogic().ListInvProducts({
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;
  const uomOptions = ITEMS_ENTITY.appLogic().List({
    adapter: CORE_ENTITY.Adapters.parseUomOptions,
  }).data;
  const { mutateAsync: updateReceipt } = INVTRANS_ENTITY.appLogic().Update();
  const transactionData = INVTRANS_ENTITY.appLogic().Get(
    recordData.locationRef,
    recordData.inventoryProductRef,
    recordData.crn
  ).data;
  const binsOptions = PRODUCT_ENTITY.appLogic().GetBalance({
    inventoryProductRef: recordData.inventoryProductRef,
    locationRef: recordData.locationRef,
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;

  const [mode] = useState(formMode ?? 'create');
  const [isLoading, setIsloading] = useState(false || recordData?.inventory === 'CB');
  const [isLoadingTrans, setIsLoadingTrans] = useState(true);
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const [isDisabledByAdjType, setIsDisabledByAdjType] = useState(true);
  const localDateTimeDayjs = dayjs().format();
  const { sendSuccessNotification, sendErrorNotification } = CORE_ENTITY.Utils.useNotification();
  const setLocationHelperTxt = locationsOptions.length === 0 ? translateMessage('info.adjustment.noRecords') : '';
  const setProductsHelperTxt = productsOptions.length === 0 ? translateMessage('info.adjustment.noRecords') : '';
  const timeZone =
    locationsOptions.find(location => location.value === recordData.locationRef)?.timeZone || 'America/Chicago';

  const [initialValues, setInitialValues] = useState(
    mode === 'create'
      ? {
          location: '',
          material: '',
          date: localDateTimeDayjs,
          time: localDateTimeDayjs,
          quantity: 0,
          unit: '',
          adjustmentType: 'stock_take',
          bins: '',
        }
      : {
          location: '',
          material: '',
          date: localDateTimeDayjs,
          time: localDateTimeDayjs,
          quantity: 0,
          unit: 'STN',
          adjustmentType: 'stock_take',
          bins: '',
        }
  );

  useEffect(() => {
    if (transactionData !== undefined) {
      const transDateTime = CORE_ENTITY.Utils.dateTimeZone(transactionData?.transDateTime, timeZone);
      const quantity =
        transactionData?.adjustmentType === 'STOCK_TAKE'
          ? transactionData?.stockTakeQuantity
          : transactionData?.quantity.value;
      const uomCode =
        transactionData?.adjustmentType === 'STOCK_TAKE'
          ? transactionData?.stockTakeQuantityUomCode
          : transactionData?.quantity.uomCode;

      setInitialValues({
        location: transactionData?.locationRef,
        material: transactionData?.inventoryProductRef,
        date: transDateTime,
        time: transDateTime,
        quantity: quantity,
        unit: uomCode,
        adjustmentType: transactionData.adjustmentType ? transactionData.adjustmentType.toLowerCase() : 'stock_take',
        bins: transactionData?.inventoryContainer || '',
      });

      setIsLoadingTrans(false);
    }
  }, [transactionData, timeZone]);

  const validationSchema = yup.object().shape({
    location: yup.string().required(translateMessage('validation.required')),
    material: yup.string().required(translateMessage('validation.required')),
    date: yup.string().required(translateMessage('validation.required')),
    time: yup.string().required(translateMessage('validation.required')),
    quantity: yup
      .number()
      .required(translateMessage('validation.required'))
      .test('has-two-decimals', translateMessage('validation.adjustment.maxTwoDecimals'), value => {
        if (value === undefined) return true;
        const decimalRegex = /^-?[0-9]+(\.[0-9]{1,2})?$/;
        const noDecimalRegex = /^-?[0-9]+$/;
        return decimalRegex.test(value.toString()) || noDecimalRegex.test(value.toString());
      }),
    unit: yup.string().required(translateMessage('validation.required')),
    adjustmentType: yup.string().required(translateMessage('validation.required')),
  });

  /**
   * disabledFieldsByAdjType is a method in charge of disable the
   * date and time field if type = stock and reset them to the
   * current date.
   *
   * @param {string} type
   * @param {function} setValues
   * @param {object} formValues
   */
  const disabledFieldsByAdjType = (type, setValues, formValues) => {
    if (type === 'stock_take') {
      setValues({
        ...formValues,
        date: localDateTimeDayjs,
        time: localDateTimeDayjs,
      });
      setIsDisabledByAdjType(true);
    } else {
      setIsDisabledByAdjType(false);
    }
  };

  /**
   * Method to update adjustment data from inventory-transaction grid
   * @param {Object} formData
   */
  const updateTransaction = async formData => {
    try {
      const formatPayload = {
        locationRef: formData.location,
        productRef: formData.material,
        transactionRef: transactionData.crn,
        payload: {
          //To Do: Add the missing keys to the data to be updated
        },
      };
      await updateReceipt(formatPayload);

      setIsloading(false);
      sendSuccessNotification('info.receipt.savedReceipt');
      onSubmitCallback();
    } catch (err) {
      setIsloading(false);
      sendErrorNotification('info.receipt.errorSavedReceipt');
      onSubmitCallback();
    }
  };

  const onSubmit = values => {
    setIsloading(true);
    if (mode === 'edit') {
      updateTransaction(values);
    }
  };

  return {
    initialValues,
    validationSchema,
    disabledFieldsByAdjType,
    isDisabledByAdjType,
    translateMessage,
    onSubmit,
    formMode: mode,
    locationsOptions,
    productsOptions,
    binsOptions,
    uomOptions,
    setLocationHelperTxt,
    setProductsHelperTxt,
    isLoading,
    isLoadingTrans,
  };
};
