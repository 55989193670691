import { css } from '@emotion/react';

export const ModalStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: ${theme.typography.fontFamily};

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .modal-content {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
    border-radius: 20px;
    height: 852px;
    width: 900px;

    .modal-header {
      display: flex;
      padding: 20px;
      gap: 5px;
      position: relative;
      height: 96px;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 38px;
      }

      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[90] })};
      }

      .close-icon {
        position: absolute;
        top: 24px;
        right: 24px;

        font-size: 28px;
        padding: 2px;
        color: ${theme.colorsCA.neutral[40]};

        /* border: 1px solid rgba(202, 196, 208, 1); */
        border-radius: 50%;

        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

        cursor: pointer;
      }
    }

    .MuiButtonBase-root {
      border-radius: 100px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .save-button {
      background: ${theme.colorsCA.darkBlue[50]};
      color: ${theme.colorsCA.neutral[100]};
      border: none;

      &:hover {
        background: ${theme.colorsCA.darkBlue[50]};
        color: ${theme.colorsCA.neutral[100]};
      }

      &:disabled {
        background: ${theme.colorsCA.neutral[80]};
        color: ${theme.colorsCA.neutral[10]};
      }
    }

    .delete-button {
      background: ${theme.colorsCA.red[50]};
      color: ${theme.colorsCA.neutral[100]};
      border: none;

      &:hover {
        background: ${theme.colorsCA.red[50]};
        color: ${theme.colorsCA.neutral[100]};
      }

      &:disabled {
        background: ${theme.colorsCA.neutral[80]};
        color: ${theme.colorsCA.neutral[10]};
      }
    }

    .modal-content-wrapper {
      height: 100%;
      overflow: auto;
      gap: 32px;

      // firefox custom scroll styles
      scrollbar-color: #a6a7a9 #e7e7e7;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e7e7e7;
        border-radius: 8px;
        border: 1px solid #cacaca;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a6a7a9;
        border-radius: 8px;
      }
    }
  }

  .modal-footer-wrapper {
    display: flex;
    justify-content: end;
    padding: 16px 20px;
    gap: 22px;

    .cancel-button {
      border: 1px solid #79747e;
    }

    .import-button {
      background: ${theme.colorsCA.neutral[100]};
      color: ${theme.colorsCA.darkBlue[30]};
      border: 1px solid #79747e;

      &:hover {
        background: ${theme.colorsCA.neutral[100]};
        color: ${theme.colorsCA.darkBlue[30]};
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .modal-content {
      width: 800px;
      height: 660px;
    }
  }

  @media screen and (max-width: 1344px) {
    .modal-content {
      width: 720px;
      height: 535px;
    }
  }
`;
