import { http } from 'connex-cds';
import { CORE_ENTITY } from 'core';

export const apiQuery = {
  list: ({ entityRef, locationRef, productRef, params }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations/${locationRef}/inventory-products/${productRef}/inventory-transactions`,
      queryParams: {
        ...params,
      },
    }),
  listByLocation: ({ entityRef, locationRef, params }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations/${locationRef}/inventory-transactions`,
      queryParams: {
        ...params,
      },
    }),
  get: ({ entityRef, locationRef, productRef, transactionRef, params }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations/${locationRef}/inventory-products/${productRef}/inventory-transactions/${transactionRef}`,
      queryParams: {
        ...params,
      },
    }),
  create: ({ entityRef, locationRef, productRef, payload }) =>
    http.post({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations/${locationRef}/inventory-products/${productRef}/inventory-transactions`,
      data: payload,
    }),
  update: ({ entityRef, locationRef, productRef, transactionRef, payload }) =>
    http.patch({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations/${locationRef}/inventory-products/${productRef}/inventory-transactions/${transactionRef}`,
      data: payload,
    }),
};
