import React from 'react';
import { IconButton } from '@mui/material';
import { DateSelector, DateRangePicker, Icon } from 'for-sdk/common';
import { useDateSelectorFilter } from './DateSelectorFilter.helper';

const DateSelectorsFilter = () => {
  const {
    onChangeDateRange,
    dateRangeValue,
    setDateRangeValue,
    setShowRangeCalendar,
    showRangeCalendar,
    setDateFilterType,
    refreshTransactions,
  } = useDateSelectorFilter();

  return (
    <>
      {showRangeCalendar && (
        <div style={{ position: 'absolute', right: 0, left: 0 }}>
          <DateRangePicker
            onCustomDateFilterTypeChange={onChangeDateRange}
            value={dateRangeValue}
            setValue={setDateRangeValue}
          />
        </div>
      )}
      <div className="ctas-content">
        <DateSelector
          disabled={false}
          handleShowCalendar={setShowRangeCalendar}
          onDateFilterTypeChange={setDateFilterType}
        />
        <IconButton onClick={refreshTransactions} className="refresh-button">
          <Icon type="refresh" />
        </IconButton>
      </div>
    </>
  );
};

export default DateSelectorsFilter;
