export const info = {
  receipt: {
    noRecords: 'No hay recursos disponibles',
    deleteMsg: '¿Está seguro que quiere eliminar este recibo del inventario de transacciones?',
    pending: 'Pendiente',
    accepted: 'Aceptado',
    'pending-not-synced': 'Pendiente no sync',
    'accepted-not-synced': 'Aceptado no sync',
    addedInventory: 'Agregado al inventario!',
    savedReceipt: 'Cambios guardados!',
    errorSavedReceipt: 'Ha ocurrido un error, por favor intenté más tarde!',
  },
};
