import React from 'react';
import styled from '@emotion/styled';
import { DialogStyles } from './style';
import cn from 'classnames';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Button } from '..';
import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';

const Styled = styled(Dialog)`
  ${DialogStyles}
`;

const CustomDialog = ({ open, title, message, handleClose, handleConfirm }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <Styled open={open} onClose={handleClose}>
      <DialogTitle className={cn('dialog-title')}>{title}</DialogTitle>
      <DialogContent className={cn('dialog-content')}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className={cn('dialog-footer')}>
        <Button className={cn('cancel-button')} onClick={handleClose}>
          {translateMessage('general.actions.cancel')}
        </Button>
        <Button className={cn('confirm-button')} onClick={handleConfirm}>
          {translateMessage('general.actions.confirm')}
        </Button>
      </DialogActions>
    </Styled>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default CustomDialog;
