import React from 'react';
import styled from '@emotion/styled';
import { Stack } from 'for-sdk/common';
import { Components } from '../../components';
import { Providers } from '../../providers';
import { CORE_ENTITY } from 'core';
import { OverviewStyles } from './styles';
import cn from 'classnames';
import { useListInventoryOverviewHelper } from './ListInventoryOverview.helper';

const Styled = styled.div`
  ${OverviewStyles};
`;

export const ListInventoryOverview = () => {
  const { filters } = useListInventoryOverviewHelper();

  return (
    <>
      <Providers.InvOverviewDataProvider>
        <Styled>
          <Stack className={cn('filters-content')} direction="row">
            <CORE_ENTITY.Components.Filters filters={filters} disabled={false} source="inventoryOverview" />
            <Stack className={cn('ctas-content')} direction="row" alignItems="center">
              <Components.DateSelectorFilter />
            </Stack>
          </Stack>
        </Styled>
        <Components.CollapsibleGroup />
      </Providers.InvOverviewDataProvider>
    </>
  );
};
