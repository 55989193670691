export const entity = {
  invTrans: {
    name: 'Inventory Transactions',
    material: 'Material',
    location: 'Location',
    type: 'Type',
    quantity: 'Quantity',
    ticketCode: 'Ticket Code',
    supplier: 'Supplier',
    hauler: 'Hauler',
    vehicle: 'Vehicle',
    poNumber: 'PO Number',
    created: 'Date',
    unit: 'Unit',
    ticket: 'Ticket',
    vendor: 'Vendor',
    receiptStatus: 'Receipt Status',
    adjustmentType: 'Adjustment Type',
    status: 'Status',
    stock_take: 'Stock Take',
    quantity_adjustment: 'Quantity Adjustment',
    bins: 'Bin / Silo',
  },
};
