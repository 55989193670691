import { useRef } from 'react';
import { TextField as MUITextField, InputAdornment } from '@mui/material';
import { withBaseField } from '../hocs';
import { Icon } from '..';

/**
 * Custom TextField component that integrates with form handling utilities.
 *
 * @param props - The properties for the TextField.
 *
 * @returns A form-aware TextField component.
 */
const BaseTextField = props => {
  /**
   * A reference to the underlying HTML input element.
   */
  const ref = useRef(null);

  return (
    <MUITextField
      {...props}
      ref={ref}
      autoComplete="off"
      {...(props.search && {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <Icon type="search" />
            </InputAdornment>
          ),
        },
      })}
    />
  );
};

/**
 * A form-aware TextField component created by enhancing the `BaseTextField`
 * component with form handling capabilities.
 *
 * @param props - The properties for the TextField, including form handling
 * props.
 *
 * @returns A form-aware TextField component.
 */
export const TextField = withBaseField(BaseTextField);

/**
 * This is used by withBaseField in order to parse the input value.
 *
 * Refer to src/common/hocs/withBaseField/withBaseField.helper.js for further details
 */
TextField.defaultProps = {
  componenttype: 'TextField',
};
