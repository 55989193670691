import { useState } from 'react';
import { CORE_ENTITY } from 'core';

export const useListInventoryTransactionsHelper = () => {
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const [filters] = useState({
    enableLocation: true,
    enableProduct: true,
    enableType: true,
    enableVendor: false,
    enableReceiptStatus: true,
  });

  return {
    translateMessage,
    filters,
  };
};
