import { css } from '@emotion/react';

export const FiltersContainerStyles = () => css`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0px 20px 0px 10px;
`;

export const FilterButtonStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
  border: 1px solid ${theme.colorsCA.neutral[50]};
  border-radius: 8px;
  box-shadow: ${theme.shadowCA(1)};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[30], dark: theme.colorsCA.neutral[95] })};
  cursor: pointer;
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1rem;
  max-height: 32px;
  text-transform: capitalize;

  &:hover {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.backgroundCA(5) })};
    border: 1px solid ${theme.colorsCA.neutral[50]};
  }

  &:disabled {
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
    border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
    pointer-events: none;

    .icon,
    .icon:first-of-type {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
    }
  }

  &.active {
    color: ${theme.colorsCA.neutral[100]};
    background: ${theme.colorsCA.aqua[30]};
    border: 1px solid ${theme.colorsCA.aqua[30]};

    .icon:first-of-type {
      color: ${theme.colorsCA.yellow[40]};
    }
  }

  .icon:first-of-type {
    font-size: 18px;
    color: ${theme.colorsCA.aqua[30]};
  }
`;
