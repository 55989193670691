import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

/**
 * A hook for handling form field input with optional Formik integration.
 *
 * @param props - The properties to configure the form field.
 *
 * @returns An object containing handlers for `onChange` and `onBlur` events,
 * the current input value, and a method to set the initial value.
 */
export const useWithBaseFieldHelper = props => {
  const [inputValue, setInputValue] = useState('');

  /**
   * Parses the input value based on the component field type
   */
  const parseValue = input => {
    switch (props.componenttype) {
      case 'TextField':
        return input.target.value;
      case 'SelectField':
        return input.target.value;
      case 'TimeField':
        /**
         * Store UTC dates but display in another timezone
         * Reference link: https://mui.com/x/react-date-pickers/timezone/#store-utc-dates-but-display-in-another-timezone
         */
        return input.format();
      case 'DateField':
        /**
         * Store UTC dates but display in another timezone
         * Reference link: https://mui.com/x/react-date-pickers/timezone/#store-utc-dates-but-display-in-another-timezone
         */
        return input.format();
      default:
        return input;
    }
  };

  /**
   * A debounced version of the `onChange` function.
   */
  const debouncedHandleChange = useCallback(
    debounce(
      (
        /**
         * ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
         */
        event
      ) => {
        // This will update Formik's state
        props.fieldprops && props.fieldprops.form.setFieldValue(props.fieldprops.field.name, parseValue(event));

        // This will update local input state
        props.onChange && props.onChange(event);
      },
      300
    ),
    []
  );

  /**
   * A debounced version of the `onBlur` function.
   */
  const debouncedBlurChange = useCallback(
    debounce(
      (
        /**
         * FocusEvent<HTMLInputElement | HTMLTextAreaElement>
         */
        event
      ) => {
        // This will update Formik's state
        props.fieldprops && props.fieldprops.form.setFieldValue(props.fieldprops.field.name, parseValue(event));

        /**
         * This is done so that when it is required to show a validation error
         * we can be sure that the field has been touched (For some reason we have
         * to set this state manually, formik doesn't do it on its own)
         *
         * Reference: https://github.com/jaredpalmer/formik/issues/955
         */
        props.fieldprops.form.setFieldTouched(props.fieldprops.field.name, true, false);

        // This will update local input state
        props.onBlur && props.onBlur(event);
      },
      300
    ),
    []
  );

  /**
   * Handles the `onChange` event for the input element.
   *
   * @param event - The `ChangeEvent` object
   *
   * React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   */
  const handleChange = event => {
    setInputValue(parseValue(event));

    debouncedHandleChange(event);
  };

  /**
   * Handles the `onBlur` event for the input element.
   *
   * @param event - The `FocusEvent` object.
   *
   * React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
   */
  const handleBlur = event => {
    setInputValue(parseValue(event));

    debouncedBlurChange(event);
  };

  /**
   * Sets the initial value of the input field based on:
   *
   * - Current form state
   * - Initial value assigned to the field when implemented outside of a form
   */
  const setInitialValue = useCallback(() => {
    setInputValue(props.fieldprops?.field.value ?? props.value);
  }, [props.fieldprops?.field.value, props.value]);

  return {
    handleBlur,
    handleChange,
    debouncedBlurChange,
    debouncedHandleChange,
    inputValue,
    setInitialValue,
  };
};
