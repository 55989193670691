import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { isEmpty } from 'lodash';
import { CORE_ENTITY } from 'core';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '22ea14ca0a454aeb72aeb91bd13be649Tz02ODk1OCxFPTE3MTg4MDI2MTM3NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

try {
  const queryParams = queryString.parse(window.location.search);

  if (!isEmpty(queryParams)) {
    const previousConnexId = localStorage.getItem('x-connex-id') || '';

    const connexId = queryParams['x-connex-id'] || previousConnexId;
    const source = queryParams['source'] || localStorage.getItem('source');
    const language = queryParams['language'] || localStorage.getItem('language');
    const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'dark';
    const entityRef = queryParams['entityRef'] || localStorage.getItem('entityRef');
    const ticketRef = queryParams['crn'];

    CORE_ENTITY.Utils.cacheItem('source', source || '');
    CORE_ENTITY.Utils.cacheItem('themeName', themeName);
    CORE_ENTITY.Utils.cacheItem('language', language || '');
    CORE_ENTITY.Utils.cacheItem('entityRef', entityRef);
    CORE_ENTITY.Utils.cacheItem('ticketRef', ticketRef);

    if (connexId) {
      CORE_ENTITY.Utils.cacheItem('x-connex-id', connexId);
    }

    // Strip query params off URL
    window.location.href = `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`;
  }
} catch (e) {
  console.log('index.js', e.message);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<CORE_ENTITY.Components.App />);
