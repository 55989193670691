import { css } from '@emotion/react';

export const DialogStyles = ({ theme }) => css`
  .dialog-title {
    padding-top: 24px;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    color: ${theme.colorsCA.neutral[10]};
  }

  .dialog-content {
    width: 400px;
    height: 337px;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.colorsCA.neutral[40]};
    }
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .MuiPaper-root {
    width: 400px;
    height: 337px;
    border-radius: 28px;
  }

  .dialog-footer {
    padding: 24px 24px 24px 8px;
    gap: 8px;

    .MuiButtonBase-root {
      border-radius: 100px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .cancel-button {
      color: ${theme.colorsCA.neutral[50]};
      background: ${theme.colorsCA.neutral[100]};

      &:hover {
        color: ${theme.colorsCA.neutral[50]};
        background: ${theme.colorsCA.neutral[100]};
      }
    }

    .confirm-button {
      color: ${theme.colorsCA.neutral[100]};
      background: ${theme.colorsCA.darkBlue[50]};
      border: none;

      &:hover {
        color: ${theme.colorsCA.neutral[100]};
        background: ${theme.colorsCA.darkBlue[50]};
      }
    }
  }
`;
