import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apiQuery } from '../query';

export const useApiHook = () => {
  const { entityRef } = useParams();

  const List = ({ adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['products', entityRef],
      queryFn: () => apiQuery.list({ entityRef }),
    });

    return !adapter
      ? queryResult
      : {
          ...queryResult,
          data: adapter({
            serviceResponse: queryResult.data,
            filteringStrategy: element => element?.inventory?.trackInventory === 'YES',
          }),
        };
  };

  const ListMutation = useMutation({
    mutationFn: ({ queryParams = {} }) =>
      apiQuery.get({
        entityRef,
        productRef: queryParams.productRef,
      }),
  });

  const ListInvProducts = ({ adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['inventory-products', entityRef],
      queryFn: () => apiQuery.listInvProducts({ entityRef }),
    });

    return !adapter
      ? queryResult
      : {
          ...queryResult,
          data: adapter({
            serviceResponse: queryResult.data,
          }),
        };
  };

  const GetBalance = ({ inventoryProductRef, locationRef, adapter = null }) => {
    const queryResult = useQuery(['getInventoryProductBalaces', `${entityRef}-${inventoryProductRef}`], () =>
      apiQuery.getBalances({ entityRef, inventoryProductRef })
    );

    const serviceData = queryResult.data;
    let formattedData = [];
    if (serviceData !== undefined && serviceData.length !== 0) {
      const locationSilos = serviceData.filter(el => el.locationRef === locationRef);
      formattedData = locationSilos.map(silo => {
        let containers = silo.inventoryContainers ? silo.inventoryContainers : [];
        containers = containers.map(el => ({
          name: el,
          crn: el,
        }));

        return {
          locationRef: silo.locationRef,
          containers,
        };
      });
    }

    return !adapter
      ? queryResult
      : {
          ...queryResult,
          data: adapter({
            serviceResponse: formattedData[0]?.containers || [],
          }),
        };
  };

  return {
    List,
    ListMutation,
    ListInvProducts,
    GetBalance,
  };
};
