import React from 'react';
import { CORE_ENTITY } from 'core';
import { RECEIPT_ENTITY } from 'entities/receipt';
import { ADJUSTMENT_ENTITY } from 'entities/adjustment';
import { USAGE_ENTITY } from 'entities/usage';
import { Modal } from 'for-sdk/common';
import PropTypes from 'prop-types';

export const TransactionForm = ({ openModal, onClickHandleModal, transactionData }) => {
  const { translateToken } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { type } = transactionData;

  const setTransactionForm = () => {
    /**
     * This method render the form content based on the
     * transaction type selected.
     */
    switch (type ? type.toLowerCase() : '') {
      case 'usage':
        return (
          <USAGE_ENTITY.Components.UsageForm
            mode="edit"
            recordData={transactionData}
            onSubmitCallback={onClickHandleModal}
          />
        );
      case 'receipt':
        return (
          <RECEIPT_ENTITY.Components.ReceiptForm
            mode="edit"
            recordData={transactionData}
            onSubmitCallback={onClickHandleModal}
          />
        );
      case 'adjustment':
        return (
          <ADJUSTMENT_ENTITY.Components.AdjustmentForm
            mode="edit"
            recordData={transactionData}
            onSubmitCallback={onClickHandleModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={openModal}
      title={type ? (type.toLowerCase() !== 'stock_take' ? translateToken(`view.${type.toLowerCase()}.edit`) : '') : ''}
      handleClose={onClickHandleModal}
      modalContent={setTransactionForm()}
    />
  );
};

TransactionForm.propTypes = {
  openModal: PropTypes.bool,
  onClickHandleModal: PropTypes.func,
  transactionData: PropTypes.object,
};
