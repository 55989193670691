export const info = {
  receipt: {
    noRecords: 'There are no available resources',
    deleteMsg: 'Are you sure you want to remove this receipt from the transaction inventory?',
    pending: 'Pending',
    accepted: 'Accepted',
    'pending-not-synced': 'Pending not synced',
    'accepted-not-synced': 'Accepted not synced',
    addedInventory: 'Added Into Inventory!',
    savedReceipt: 'Changes Saved!',
    errorSavedReceipt: 'An error has ocurred, please try later!',
  },
};
