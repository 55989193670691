import menuLogo from './assets/menu-logo.svg';

export default {
  menuLogo,
  sidebarOptions: [
    {
      id: 'inventoryOverview',
      labelStringId: 'entity.invOverview.name',
      path: '/inventoryOverview',
      iconName: 'ContentPasteOutlined',
    },
    {
      id: 'inventoryTransactions',
      labelStringId: 'entity.invTrans.name',
      path: '/inventoryTransactions',
      iconName: 'DescriptionOutlined',
    },
  ],
};
