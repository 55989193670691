import { useApiHook } from './api';
import { Components } from './components';
import { i18n } from './i18n';
import { Views } from './views';

export const INVTRANS_ENTITY = {
  appLogic: useApiHook,
  Components,
  i18n,
  Views,
};
