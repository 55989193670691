import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon, Stack } from 'for-sdk/common';
import { TransactionForm } from './components';
import { DataGridActionsStyles } from './style';
import PropTypes from 'prop-types';

const Styled = styled(Stack)`
  ${DataGridActionsStyles}
`;

const TableActions = ({ rowData }) => {
  const [showModal, setShowModal] = useState(false);

  const onClickHandleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Styled>
      {rowData?.type !== 'STOCK_TAKE' ? <Icon type="edit" onClick={onClickHandleModal} /> : <Icon type="editOff" />}
      <TransactionForm openModal={showModal} onClickHandleModal={onClickHandleModal} transactionData={rowData} />
    </Styled>
  );
};

TableActions.propTypes = {
  rowData: PropTypes.object,
};

export default TableActions;
