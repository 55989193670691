import React, { useEffect, useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { CORE_ENTITY } from 'core';
import { InputAdornment } from '@mui/material';
import { Icon } from 'for-sdk/common';
import { normalizeText } from './SearchFilter.helper';
import { useTransactionsDataContext } from '../transaction-data-provider';

import { SearchBarStyled } from './style';

const SearchFilter = ({ disabled, options }) => {
  const [value, setValue] = useState('');
  const searchRef = useRef(null);
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { SearchQueryTransaction } = useTransactionsDataContext();

  const debouncedSearch = debounce(() => {
    SearchQueryTransaction(normalizeText(value));
  }, 200);

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [value, debouncedSearch]);

  const handleInputChange = useCallback(
    e => {
      const inputValue = e.target?.value;
      if (inputValue.length <= 50) {
        setValue(inputValue);
      }
    },
    [setValue]
  );

  const handleInputClear = useCallback(() => setValue(''), [setValue]);

  return (
    <SearchBarStyled
      inputRef={searchRef}
      variant="outlined"
      autoComplete="off"
      placeholder={translateMessage('general.actions.search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon type="search" onClick={() => searchRef.current.focus()} sx={{ fontSize: 24 }} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <Icon type="closeOutlined" onClick={handleInputClear} sx={{ fontSize: 24, cursor: 'pointer' }} />
          </InputAdornment>
        ),
      }}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          searchRef.current.blur();
        }
      }}
      className="search-filter"
      disabled={disabled}
      onChange={handleInputChange}
      value={value}
    />
  );
};

export default SearchFilter;
