import { API, ConnexDesignSystem, Core } from 'connex-cds';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import messages from '../i18n';
import menuConfig from '../menu-config';
import ThemeProvider from 'for-sdk/theme/ThemeProvider';
import { TanStackProvider } from 'for-sdk/common';
import { Constants } from '../constants';
import { AppRoutes } from './AppRoutes';

API.setSource(Constants.API_NAME);

export const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  return (
    <BrowserRouter>
      <TanStackProvider>
        <Core.Spinner spin={isConfiguring}>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId={Constants.API_NAME}
            breakpoints={[950]}
            menuConfig={menuConfig}
          >
            <ThemeProvider>
              <AppRoutes />
            </ThemeProvider>
          </ConnexDesignSystem>
        </Core.Spinner>
      </TanStackProvider>
    </BrowserRouter>
  );
};
