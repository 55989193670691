import { GridFooterContainer } from '@mui/x-data-grid-pro';
import { CORE_ENTITY } from 'core';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TableFooter = ({ selectedCount, totalCount }) => {
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();

  return (
    <GridFooterContainer>
      <p className={cn('count-selected')}>
        {selectedCount > 0 &&
          `${selectedCount} ${translateMessage(
            selectedCount > 1
              ? 'component.invTrans.gridFooter.selectionPlural'
              : 'component.invTrans.gridFooter.selectionSingle'
          )}`}
      </p>
      <p className={cn('count-total')}>{`${translateMessage(
        'component.invTrans.gridFooter.count'
      )} ${totalCount} ${translateMessage(
        totalCount > 1 ? 'component.invTrans.gridFooter.countPlural' : 'component.invTrans.gridFooter.countSingle'
      )}`}</p>
    </GridFooterContainer>
  );
};

TableFooter.propTypes = {
  selectedCount: PropTypes.number,
  totalCount: PropTypes.number,
};

export default TableFooter;
