import { ADJUSTMENT_ENTITY } from 'entities/adjustment';
import { RECEIPT_ENTITY } from 'entities/receipt';
import { USAGE_ENTITY } from 'entities/usage';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { PRODUCT_ENTITY } from 'entities/product';

export const info = {
  ...ADJUSTMENT_ENTITY.i18n['es-CO'].info,
  ...RECEIPT_ENTITY.i18n['es-CO'].info,
  ...USAGE_ENTITY.i18n['es-CO'].info,
  ...INVTRANS_ENTITY.i18n['es-CO'].info,
  ...PRODUCT_ENTITY.i18n['es-CO'].info,
};
