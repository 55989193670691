import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';
import IconStyles from './style';
import iconNames from './iconList';

const Icon = ({ type, className: classNameProp = '', ...props }) => {
  const IconComponent = useMemo(() => iconNames[type], [type]);
  if (!IconComponent) return null;
  return <IconComponent className={cn('icon', classNameProp)} {...props} />;
};

const Styled = styled(Icon)`
  ${IconStyles}
`;

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Styled;
