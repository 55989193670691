import React from 'react';
import styled from '@emotion/styled';
import { CardStyles } from './style';
import { Stack } from '..';
import { Card as MUICard, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

const Styled = styled(MUICard)`
  ${CardStyles}
`;

const Card = ({ borderColor, cardContent, cardHeader }) => {
  return (
    <Styled style={{ borderTop: `5px solid ${borderColor}` }} elevation={3}>
      <Stack>{cardHeader}</Stack>
      <CardContent>{cardContent}</CardContent>
    </Styled>
  );
};

Card.propTypes = {
  borderColor: PropTypes.string,
  cardContent: PropTypes.node,
  cardHeader: PropTypes.node,
};

export default Card;
