import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apiQuery } from '../query';

export const useApiHook = () => {
  const { entityRef } = useParams();

  const List = ({ queryParams = {}, adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['inventoryOverview', entityRef],
      queryFn: () =>
        apiQuery.list({
          entityRef,
          params: queryParams.filters,
        }),
    });

    return !adapter ? queryResult : { ...queryResult, data: adapter(queryResult.data) };
  };

  const ListMutation = useMutation({
    mutationFn: ({ queryParams = {} }) =>
      apiQuery.list({
        entityRef,
        params: queryParams.filters,
      }),
  });

  return {
    List,
    ListMutation,
  };
};
