/**
 * Include all translation keys serving a 'general' purpose, they can be part of
 * a specific implementation but their main responsibility is to add value to any
 * key requiring these pieces of translation to be displayed as they are expected
 * to or, simply to provide a 'general' string to a component.
 */
export const general = {
  time_period_option: {
    today: 'Hoy',
    yesterday: 'Ayer',
    this_week: 'Esta semana',
    this_month: 'Este mes',
    last_month: 'El mes pasado',
    custom_dates: 'Personalizado',
  },
  actions: {
    save: 'Guardar',
    confirm: 'Confirmar',
    search: 'Buscar',
    cancel: 'Cancelar',
    done: 'Listo',
    addInventory: 'Agregar al inventario',
  },
  filter: {
    all: 'Todos',
  },
};
