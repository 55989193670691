import { ADJUSTMENT_ENTITY } from 'entities/adjustment';
import { RECEIPT_ENTITY } from 'entities/receipt';
import { USAGE_ENTITY } from 'entities/usage';

export const validation = {
  required: 'This field is required',
  ...ADJUSTMENT_ENTITY.i18n['en-US'].validation,
  ...RECEIPT_ENTITY.i18n['en-US'].validation,
  ...USAGE_ENTITY.i18n['en-US'].validation,
};
