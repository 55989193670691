import { useState, useEffect } from 'react';
import { useTransactionsDataContext } from '../transaction-data-provider';
import { CORE_ENTITY } from 'core';

export const useDateSelectorFilter = () => {
  const { setDateOption, refreshTransactions } = useTransactionsDataContext();
  const [dateFilterType, setDateFilterType] = useState('Today');
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [showRangeCalendar, setShowRangeCalendar] = useState(false);
  const { sendErrorNotification } = CORE_ENTITY.Utils.useNotification();

  const onChangeDateRange = dates => {
    const startDate = new Date(dates.startDate).toISOString().replace(/.\d+Z$/g, 'Z');

    const endDateTimestamp = new Date(dates.endDate);
    const endDate = new Date(endDateTimestamp).toISOString().replace(/.\d+Z$/g, 'Z');

    // Check to see if the date range is valid
    if (new Date(startDate) > new Date(endDate)) {
      sendErrorNotification('component.dateRangePicker.invalidDate');
      return;
    }

    // Check to see if the date range exceeds 31 days
    const diffInDays = Math.ceil((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
    if (diffInDays > 31) {
      sendErrorNotification('component.dateRangePicker.invalidRange');
      return;
    }

    // Send data to context
    setDateOption({ startDate, endDate });
  };

  useEffect(() => {
    if (!showRangeCalendar) {
      setDateOption({ dateOption: dateFilterType });
    } else {
      setDateFilterType('Custom');
    }
  }, [setDateOption, dateFilterType, showRangeCalendar, setDateFilterType]);

  return {
    onChangeDateRange,
    dateRangeValue,
    setDateRangeValue,
    setShowRangeCalendar,
    showRangeCalendar,
    setDateFilterType,
    refreshTransactions,
  };
};
