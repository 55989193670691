import { http } from 'connex-cds';
import { CORE_ENTITY } from 'core';

export const apiQuery = {
  list: ({ entityRef, params }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/locations`,
      queryParams: {
        ...params,
      },
    }),
  get: ({ entityRef, locationRef }) =>
    http.get({ apiName: CORE_ENTITY.Constants.API_NAME, path: `/${entityRef}/locations/${locationRef}` }),
  listPartners: ({ entityRef }) =>
    http.get({ apiName: CORE_ENTITY.Constants.API_NAME, path: `/${entityRef}/partners` }),
};
