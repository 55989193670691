import React from 'react';
import { Button, Icon, DateField } from 'for-sdk/common';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DatePerDayPickerStyles } from './style';
import styled from '@emotion/styled';
import { CORE_ENTITY } from 'core';

const Styled = styled.div`
  ${DatePerDayPickerStyles}
`;

const DatePerDayPicker = ({ dateValue, handleArrows, onChangeHandler }) => {
  const { translateToken } = CORE_ENTITY.Utils.useLocalizationProvider();

  return (
    <Styled>
      <Button className={cn('ctas-btn')} onClick={() => handleArrows('left', dateValue)}>
        <Icon type="arrowLeft" />
      </Button>
      <DateField
        className={cn('datePicker')}
        value={dateValue}
        size={'small'}
        label={translateToken('component.datePicker.selectDate')}
        onChange={onChangeHandler}
      />
      <Button className={cn('ctas-btn')} onClick={() => handleArrows('right', dateValue)}>
        <Icon type="arrowRight" />
      </Button>
    </Styled>
  );
};

export default DatePerDayPicker;

DatePerDayPicker.propTypes = {
  onChangeHandler: PropTypes.func,
  dateValue: PropTypes.any,
  handleArrows: PropTypes.func,
};
