import React from 'react';
import { Button, Icon } from 'for-sdk/common';
import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';
import { FilterButtonStyles } from './style';

const Styled = styled(Button)`
  ${FilterButtonStyles}
`;

const FilterButton = ({ isActive, translationId, type, isDropdown, disabled, onClick, isPressed, label }) => {
  return (
    <Styled className={cn({ active: isActive })} disabled={!isActive && disabled} onClick={onClick} size="small">
      <Icon className={cn('icon')} type={type} />
      {translationId !== '' ? <Localization.Translate stringId={translationId} /> : label}
      {isDropdown ? <Icon type={isPressed ? 'arrowUp' : 'arrowDown'} /> : null}
    </Styled>
  );
};

FilterButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  isDropdown: PropTypes.bool,
  isPressed: PropTypes.bool,
  onClick: PropTypes.func,
  translationId: PropTypes.string,
  label: PropTypes.string,
};

export default FilterButton;
