import { useInvOverviewDataContext } from '../../providers/overview-data-provider';
import { CORE_ENTITY } from 'core';

export const useDemandGraphHelper = ({ maxStock, safetyStock, onHandStock, graphDataSet }) => {
  const { dateOption, showChartDemand, locationTimeZone } = useInvOverviewDataContext();
  const currentHours = CORE_ENTITY.Utils.dateTimeZone(new Date(), locationTimeZone.current);
  const filterDate =
    dateOption.dateOption === 'Today'
      ? currentHours
      : new Date(dateOption.startDate).setHours(currentHours.getHours(), currentHours.getMinutes());
  const currentDay = new Date(filterDate);
  const starPointDate = CORE_ENTITY.Utils.dateTimeZone(new Date(filterDate), locationTimeZone.current);
  const nextDay = new Date(currentDay);
  nextDay.setDate(currentDay.getDate() + 1);
  const fallBackData = [
    {
      stockLvl: onHandStock,
      withAllDemandLvl: onHandStock,
      withConfirmedLvl: onHandStock,
      transDateTime: new Date(starPointDate.setHours(0, 0, 0)),
    },
  ];

  const addStartEndPointToDataSet = () => {
    let completeDataSet = [];
    const transactions = graphDataSet.filter(el => el.type !== 'confirmed' && el.type !== 'will call');
    const confirmed = graphDataSet.filter(el => el.type === 'confirmed');
    const allDemand = graphDataSet.filter(el => el.type === 'confirmed' || el.type === 'will call');
    // Get last points quantity
    const lastTransQuantity = transactions.length !== 0 ? transactions[transactions.length - 1]?.stockLvl : onHandStock;
    const lastConfirmedQuantity =
      confirmed.length !== 0 ? confirmed[confirmed.length - 1]?.withConfirmedLvl : lastTransQuantity;
    const lastAllDemandQuantity =
      allDemand.length !== 0 ? allDemand[allDemand.length - 1]?.withAllDemandLvl : lastTransQuantity;

    // Add start point using onHandQuantity
    const withStartPoint = [...fallBackData, ...graphDataSet];

    // Add end point for current day
    const withEndPoint = [];
    if (showChartDemand) {
      withEndPoint.push({
        stockLvl: lastTransQuantity,
        withAllDemandLvl: lastAllDemandQuantity,
        withConfirmedLvl: lastConfirmedQuantity,
        transDateTime: new Date(starPointDate.setHours(23, 59, 59)),
      });
    } else {
      // Add end point for previous days
      withEndPoint.push({
        stockLvl: lastTransQuantity,
        withAllDemandLvl: lastTransQuantity,
        withConfirmedLvl: lastTransQuantity,
        transDateTime: new Date(starPointDate.setHours(23, 59, 59)),
      });
    }

    completeDataSet = [...withStartPoint, ...withEndPoint];
    let sortDataSet = CORE_ENTITY.Utils.sortByKey(completeDataSet, 'transDateTime');

    // Add and extend current point
    if (dateOption?.dateOption === 'Today' || currentHours.getDate() === currentDay.getDate()) {
      completeDataSet.push({
        type: 'current',
        transDateTime: new Date(currentDay),
      });

      sortDataSet = CORE_ENTITY.Utils.sortByKey(completeDataSet, 'transDateTime');
      const currentIndex = sortDataSet.findIndex(el => el.type === 'current');
      const previousPoint = sortDataSet[currentIndex - 1];
      sortDataSet[currentIndex] = {
        stockLvl: previousPoint.stockLvl,
        withAllDemandLvl: previousPoint.withAllDemandLvl,
        withConfirmedLvl: previousPoint.withConfirmedLvl,
        transDateTime: new Date(currentDay),
      };
    }

    return sortDataSet;
  };

  return {
    currentDay,
    nextDay,
    currentHours,
    fallBackData,
    dataSet: addStartEndPointToDataSet(),
  };
};
