import { INVTRANS_ENTITY } from 'entities/inventory-transaction';

export const component = {
  datePicker: {
    startDate: 'Seleccione fecha inicio',
    endDate: 'Seleccione fecha fin',
    selectDate: 'Seleccione fecha',
  },
  dateRangePicker: {
    invalidDate: 'La fecha de inicio no puede ser posterior a la fecha de finalización',
    invalidRange: 'El rango de fechas no puede exceder los 31 días',
  },
  ...INVTRANS_ENTITY.i18n['es-CO'].component,
};
