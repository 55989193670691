import common from '../common';
import { component } from './component';
import { entity } from './entity';
import { general } from './general';
import { info } from './info';
import { validation } from './validation';
import { view } from './view';

export default {
  ...common,
  general,
  info,
  entity,
  component,
  validation,
  view,
};
