import { css } from '@emotion/react';

export const CardHeaderStyles = ({ theme }) => css`
  font-family: ${theme.typography.fontFamily};
  height: 86px;
  gap: 20px;

  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin: 0px;
    color: ${theme.colorsCA.neutral[10]};
  }

  .header-matId {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    color: ${theme.colorsCA.neutral[40]};
  }

  .MuiButtonBase-root {
    border-radius: 100px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
  }

  .receipt-button {
    color: ${theme.colorsCA.darkBlue[50]};
    background: ${theme.colorsCA.neutral[99]};

    &:hover {
      color: ${theme.colorsCA.darkBlue[50]};
      background: ${theme.colorsCA.neutral[99]};
      border: none;
    }
  }

  .adjustment-button {
    color: ${theme.colorsCA.darkBlue[50]};
    background: ${theme.colorsCA.neutral[99]};

    &:hover {
      color: ${theme.colorsCA.darkBlue[50]};
      background: ${theme.colorsCA.neutral[99]};
      border: none;
    }
  }
`;
