import { useRef } from 'react';
import { TextField as MUITextField, MenuItem } from '@mui/material';
import { withBaseField } from '../hocs';
import PropTypes from 'prop-types';

/**
 * Custom TextField component that integrates with form handling utilities.
 *
 * @param props - The properties for the TextField.
 *
 * @returns A form-aware TextField component.
 */
const BaseSelectField = props => {
  /**
   * A reference to the underlying HTML input element.
   */
  const ref = useRef(null);

  return (
    <MUITextField {...props} ref={ref} autoComplete="off" select>
      {props?.options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUITextField>
  );
};

/**
 * A form-aware TextField component created by enhancing the `BaseSelectField`
 * component with form handling capabilities.
 *
 * @param props - The properties for the TextField, including form handling
 * props.
 *
 * @returns A form-aware SelectField component.
 */
export const SelectField = withBaseField(BaseSelectField);

BaseSelectField.propTypes = {
  options: PropTypes.array.isRequired,
};

/**
 * This is used by withBaseField in order to parse the input value.
 *
 * Refer to src/common/hocs/withBaseField/withBaseField.helper.js for further details
 */
SelectField.defaultProps = {
  componenttype: 'SelectField',
};
