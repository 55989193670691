import { http } from 'connex-cds';
import { CORE_ENTITY } from 'core';

export const apiQuery = {
  list: ({ entityRef }) => http.get({ apiName: CORE_ENTITY.Constants.API_NAME, path: `/${entityRef}/products` }),
  get: ({ entityRef, productRef }) =>
    http.get({ apiName: CORE_ENTITY.Constants.API_NAME, path: `/${entityRef}/products/${productRef}` }),
  listInvProducts: ({ entityRef }) =>
    http.get({ apiName: CORE_ENTITY.Constants.API_NAME, path: `/${entityRef}/inventory-products` }),
  getBalances: ({ entityRef, inventoryProductRef }) =>
    http.get({
      apiName: CORE_ENTITY.Constants.API_NAME,
      path: `/${entityRef}/inventory-products/${inventoryProductRef}/inventory-product-locations`,
    }),
};
