import { Localization } from 'connex-cds';

/**
 * Custom hook for providing localization functionality.
 *
 * This hook provides access to translation functions and the localization context
 * from the Connex CDS library.
 *
 * @returns {object} An object containing translation and localization context.
 *
 * @property {function} translateToken - A function to translate a message based on a token.
 * @property {object} context - The localization context provided by Connex CDS.
 */
export const useLocalizationProvider = () => {
  const { useTranslateMessage, useLocalizationContext } = Localization;
  const translate = useTranslateMessage();
  const context = useLocalizationContext();

  /**
   * Translates a message using the provided token.
   *
   * @param {string} token - The token representing the message to be translated.
   *
   * @returns {string} The translated message.
   */
  const translateToken = token => translate(token);

  return { translateToken, context };
};
