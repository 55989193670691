export const entity = {
  adjustment: {
    name: 'Adjustment',
    material: 'Material',
    location: 'Location',
    type: 'Adjustment Type',
    currentOnHand: 'Current On-Hand',
    unit: 'Unit',
    date: 'Date',
    quantity: 'Quantity',
    time: 'Time',
    devices: 'Devices',
    bins: 'Bin / Silo',
  },
};
