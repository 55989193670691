import React from 'react';
import styled from '@emotion/styled';
import { AccordionStyles } from './style';
import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Icon } from '..';
import PropTypes from 'prop-types';

const Styled = styled(MUIAccordion)`
  ${AccordionStyles}
`;

const Accordion = ({ header, styletheme, content, enableExpandIcon, isExpanded = false, onChangeExpanded }) => {
  return (
    <Styled
      elevation={styletheme !== 'main' ? 0 : 3}
      disableGutters
      expanded={isExpanded}
      onChange={onChangeExpanded}
      styletheme={styletheme}
    >
      <AccordionSummary expandIcon={enableExpandIcon && <Icon type="expandMore" />}>{header}</AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Styled>
  );
};

Accordion.propTypes = {
  header: PropTypes.element,
  enableExpandIcon: PropTypes.bool,
  content: PropTypes.any,
  isExpanded: PropTypes.bool,
  onChangeExpanded: PropTypes.func,
  styletheme: PropTypes.string,
};

export default Accordion;
