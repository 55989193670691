import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apiQuery } from '../query';

export const useApiHook = () => {
  const { entityRef } = useParams();

  const List = ({ adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['locations', entityRef],
      queryFn: () =>
        apiQuery.list({
          entityRef,
          params: {
            inventoryOnly: 'true',
          },
        }),
    });

    return !adapter ? queryResult : { ...queryResult, data: adapter({ serviceResponse: queryResult.data }) };
  };

  const ListMutation = useMutation({
    mutationFn: ({ queryParams = {} }) =>
      apiQuery.get({
        entityRef,
        locationRef: queryParams.locationRef,
      }),
  });

  const ListPartners = ({ adapter = null }) => {
    const queryResult = useQuery({
      queryKey: ['partners', entityRef],
      queryFn: () =>
        apiQuery.listPartners({
          entityRef,
        }),
    });

    return !adapter ? queryResult : { ...queryResult, data: adapter({ serviceResponse: queryResult.data }) };
  };

  return {
    List,
    ListMutation,
    ListPartners,
  };
};
