import { useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker';
import { withBaseField } from '../hocs';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * A base time field component that wraps the Material-UI TimePicker.
 *
 * @param props - The TimePickerProps to pass to the underlying TimePicker.
 *
 * @returns Returns a JSX element representing the BaseTimeField.
 */
const BaseTimeField = props => {
  const ref = useRef(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUITimePicker {...props} ref={ref} value={props.value !== '' ? dayjs(props.value) : null} />
    </LocalizationProvider>
  );
};

/**
 * A form-aware TimePicker component created by enhancing the `BaseTimeField`
 * component with form handling capabilities.
 *
 * @param props - The properties for the TimePicker, including form handling
 * props.
 *
 * @returns A form-aware TimePicker component.
 */
export const TimeField = withBaseField(BaseTimeField);

/**
 * This is used by withBaseField in order to parse the input value.
 *
 * Refer to src/hocs/withBaseField/withBaseField.helper.ts for further details
 */
TimeField.defaultProps = {
  componenttype: 'TimeField',
};
