export const defaultLocationOptions = [
  {
    value: 'All',
    translation: {
      id: 'general.filter.all',
      onSelectedId: 'entity.invTrans.location',
    },
  },
];

export const defaultProductOptions = [
  {
    value: 'All',
    translation: {
      id: 'general.filter.all',
      onSelectedId: 'entity.invTrans.material',
    },
    locations: { all: true },
  },
];

export const defaultTypeOptions = [
  {
    value: 'All',
    translation: {
      id: 'general.filter.all',
      onSelectedId: 'entity.invTrans.type',
    },
  },
  {
    value: 'receipt',
    translation: 'entity.receipt.name',
  },
  {
    value: 'usage',
    translation: 'entity.usage.name',
  },
  {
    value: 'adjustment',
    translation: 'entity.adjustment.name',
  },
];

export const defaultVendorOptions = [
  {
    value: 'All',
    translation: {
      id: 'general.filter.all',
      onSelectedId: 'component.invTrans.filter.vendor.title',
    },
  },
  {
    value: 'internal',
    translation: 'component.invTrans.filter.vendor.option.internal',
  },
];

export const defaultReceiptStatusOptions = [
  {
    value: 'All',
    translation: {
      id: 'general.filter.all',
      onSelectedId: 'entity.invTrans.receiptStatus',
    },
  },
  {
    value: 'PENDING',
    translation: 'info.receipt.pending',
  },
  {
    value: 'ACCEPTED',
    translation: 'info.receipt.accepted',
  },
];
