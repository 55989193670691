import React from 'react';
import { Theme } from 'connex-cds';
import { ChartsReferenceLine, LineChart } from '@mui/x-charts';
import { useDemandGraphHelper } from './DemandGraph.helper';
import PropTypes from 'prop-types';

const DemandGraph = ({ axisRange, maxStock, safetyStock, onHandStock, graphDataSet, reorderStock }) => {
  const { currentDay, nextDay, currentHours, dataSet } = useDemandGraphHelper({
    maxStock,
    safetyStock,
    onHandStock,
    graphDataSet,
  });
  const { themeName } = Theme.useThemeContext();

  return (
    <LineChart
      dataset={dataSet}
      xAxis={[
        {
          dataKey: 'transDateTime',
          scaleType: 'time',
          min: new Date(currentDay).setHours(0, 0, 0, 0),
          max: new Date(nextDay).setHours(0, 0, 0, 0),
        },
      ]}
      yAxis={[{ min: axisRange.minYAxis, max: axisRange.maxYAxis }]}
      series={[
        {
          curve: 'step',
          dataKey: 'withAllDemandLvl',
          showMark: false,
          color: 'lightgray',
          connectNulls: true,
        },
        {
          curve: 'step',
          dataKey: 'withConfirmedLvl',
          showMark: false,
          color: 'gray',
          connectNulls: true,
        },
        {
          curve: 'step',
          dataKey: 'stockLvl',
          showMark: false,
          color: themeName === 'light' ? 'black' : 'white',
          connectNulls: true,
        },
      ]}
      height={350}
      tooltip={{ trigger: 'axis' }}
      slotProps={{
        legend: { hidden: true },
      }}
    >
      <ChartsReferenceLine y={maxStock} lineStyle={{ stroke: '#6750A4' }} />
      <ChartsReferenceLine y={reorderStock} lineStyle={{ stroke: '#27AF5D' }} />
      <ChartsReferenceLine y={safetyStock} lineStyle={{ stroke: '#EBA625' }} />
      <ChartsReferenceLine y={0} lineStyle={{ stroke: '#E2462E' }} />
      <ChartsReferenceLine x={currentHours} lineStyle={{ stroke: '#249FE0' }} />
    </LineChart>
  );
};

export default DemandGraph;

DemandGraph.propTypes = {
  axisRange: PropTypes.object,
  maxStock: PropTypes.number,
  safetyStock: PropTypes.number,
  reorderStock: PropTypes.number,
  onHandStock: PropTypes.number,
  graphDataSet: PropTypes.array,
};
