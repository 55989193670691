import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { CORE_ENTITY } from 'core';
import { LOCATION_ENTITY } from 'entities/location';
import { PRODUCT_ENTITY } from 'entities/product';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { ITEMS_ENTITY } from 'entities/unit-of-measure';

export const useFormContentHelper = ({ formMode, recordData, onSubmitCallback }) => {
  const locationsOptions = LOCATION_ENTITY.appLogic().List({
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;
  const productsOptions = PRODUCT_ENTITY.appLogic().ListInvProducts({
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;
  const { mutateAsync: updateReceipt } = INVTRANS_ENTITY.appLogic().Update();
  const transactionData = INVTRANS_ENTITY.appLogic().Get(
    recordData.locationRef,
    recordData.inventoryProductRef,
    recordData.crn,
    formMode === 'create' && {
      convertUom: CORE_ENTITY.Constants.UomIsoCodes.tons,
    }
  ).data;
  const binsOptions = PRODUCT_ENTITY.appLogic().GetBalance({
    inventoryProductRef: recordData.inventoryProductRef,
    locationRef: recordData.locationRef,
    adapter: CORE_ENTITY.Adapters.toDropdownOptions,
  }).data;
  const uomOptions = ITEMS_ENTITY.appLogic().List({
    adapter: CORE_ENTITY.Adapters.parseUomOptions,
  }).data;

  const [mode] = useState(formMode ?? 'create');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsloading] = useState(false || recordData?.inventory === 'CB');
  const [isLoadingTrans, setIsLoadingTrans] = useState(true);
  const [isReceiptSynced, setIsReceiptSynced] = useState(false);
  const localTime = dayjs().format();
  const [initialValues, setInitialValues] = useState(
    mode === 'create'
      ? {
          location: '',
          material: '',
          date: dayjs.utc(),
          time: localTime,
          quantity: 0.0,
          unit: 'STN',
          vendor: 'internal',
          vehicle: '',
          hauler: '',
          ticket: '',
          purchaseOrder: '',
          receiptStatus: 'PENDING',
          bins: '',
        }
      : {
          location: '',
          material: '',
          date: dayjs.utc(),
          time: localTime,
          quantity: 0,
          unit: 'STN',
          vendor: 'internal',
          vehicle: '',
          hauler: '',
          ticket: '',
          purchaseOrder: '',
          receiptStatus: 'PENDING',
          bins: '',
        }
  );

  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();
  const { sendSuccessNotification, sendErrorNotification } = CORE_ENTITY.Utils.useNotification();
  const setLocationHelperTxt = locationsOptions.length === 0 ? translateMessage('info.receipt.noRecords') : '';
  const setProductsHelperTxt = productsOptions.length === 0 ? translateMessage('info.receipt.noRecords') : '';
  const saveBtnTxt =
    formMode === 'create' ? translateMessage('general.actions.addInventory') : translateMessage('general.actions.save');
  const timeZone =
    locationsOptions.find(location => location.value === recordData.locationRef)?.timeZone || 'America/Chicago';

  const validationSchema = yup.object().shape({
    location: yup.string().required(translateMessage('validation.required')),
    material: yup.string().required(translateMessage('validation.required')),
    date: yup.string().required(translateMessage('validation.required')),
    time: yup.string().required(translateMessage('validation.required')),
    quantity: yup
      .number()
      .required(translateMessage('validation.required'))
      .max(1000, translateMessage('validation.receipt.max_number_1000'))
      .test('has-two-decimals', translateMessage('validation.receipt.maxTwoDecimals'), value => {
        if (value === undefined) return true;
        const decimalRegex = /^-?[0-9]+(\.[0-9]{1,2})?$/;
        const noDecimalRegex = /^-?[0-9]+$/;
        return decimalRegex.test(value.toString()) || noDecimalRegex.test(value.toString());
      }),
    unit: yup.string().required(translateMessage('validation.required')),
    vendor: yup.string().required(translateMessage('validation.required')),
    vehicle: yup.string().max(50, translateMessage('validation.receipt.max_chars_50')),
    hauler: yup.string().max(50, translateMessage('validation.receipt.max_chars_50')),
    ticket: yup.string().required(translateMessage('validation.required')),
    receiptStatus: yup.string().required(translateMessage('validation.required')),
  });

  useEffect(() => {
    if (transactionData !== undefined) {
      const transDateTime = CORE_ENTITY.Utils.dateTimeZone(transactionData?.transDateTime, timeZone);
      setIsReceiptSynced(transactionData?.receiptStatus === 'PENDING-NOT-SYNCED');
      const convertReceiptStatus = transactionData?.receiptStatus;

      setInitialValues({
        location: transactionData?.locationRef,
        material: transactionData?.inventoryProductRef,
        date: transDateTime,
        time: transDateTime,
        quantity: transactionData?.quantity?.value ? transactionData.quantity.value : 0,
        unit: transactionData?.quantity?.uomCode ? transactionData.quantity.uomCode : 'STN',
        vendor: transactionData?.supplierName ? transactionData?.supplierName : 'internal',
        vehicle: transactionData?.vehicleDescription || '',
        hauler: transactionData?.carrierName || '',
        ticket: transactionData?.ticketId || '',
        purchaseOrder: transactionData?.purchaseOrder || '',
        receiptStatus: convertReceiptStatus,
        bins: transactionData?.inventoryContainer || '',
      });

      setIsLoadingTrans(false);
    }
  }, [transactionData, timeZone]);

  /**
   * Method to change receipt status from pending to
   * accepted from inventory-overview enRoute cta.
   * @param {Object} formData
   */
  const addIntoInventory = async formData => {
    try {
      const formatPayload = {
        locationRef: formData.location,
        productRef: formData.material,
        transactionRef: transactionData.crn,
        payload: {
          receiptStatus: 'ACCEPTED',
        },
      };

      await updateReceipt(formatPayload);

      setIsloading(false);
      sendSuccessNotification('info.receipt.addedInventory');
      onSubmitCallback({ completed: true, mode: 'add' });
    } catch (err) {
      setIsloading(false);
      sendErrorNotification('info.receipt.errorSavedReceipt');
      onSubmitCallback({ completed: false, mode: 'add' });
    }
  };

  /**
   * Method to update receipt data from inventory-transaction grid
   * @param {Object} formData
   */
  const updateTransaction = async formData => {
    try {
      const formatPayload = {
        locationRef: formData.location,
        productRef: formData.material,
        transactionRef: transactionData.crn,
        payload: {
          receiptStatus: formData.receiptStatus,
          //To Do: Add the missing keys to the data to be updated
        },
      };
      const response = await updateReceipt(formatPayload);
      console.log('update api response =>', response);

      setIsloading(false);
      sendSuccessNotification('info.receipt.savedReceipt');
      onSubmitCallback({ completed: true, mode: 'edit' });
    } catch (err) {
      setIsloading(false);
      sendErrorNotification('info.receipt.errorSavedReceipt');
      onSubmitCallback({ completed: false, mode: 'edit' });
    }
  };

  const onSubmit = values => {
    setIsloading(true);
    if (mode === 'create') {
      addIntoInventory(values);
    } else {
      updateTransaction(values);
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    formMode: mode,
    locationsOptions,
    productsOptions,
    binsOptions,
    uomOptions,
    setLocationHelperTxt,
    setProductsHelperTxt,
    translateMessage,
    saveBtnTxt,
    isDisabled,
    setIsDisabled,
    isLoading,
    isLoadingTrans,
    isReceiptSynced,
  };
};
