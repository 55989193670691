import React from 'react';
import styled from '@emotion/styled';
import { GridFooterStyles } from './style';
import { Stack, Dialog, Button } from 'for-sdk/common';
import { useFooterHelper } from './Footer.helper';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Styled = styled(Stack)`
  ${GridFooterStyles}
`;

export const GridFormFooter = ({ children, withDeleteAction, withStepAction }) => {
  const { openDialog, handleDialogClose, handleDialogConfirm } = useFooterHelper({
    withDeleteAction,
  });

  return (
    <Styled>
      <Dialog
        open={openDialog}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        title={withDeleteAction.strings.dialogTitle}
        message={withDeleteAction.strings.deleteDialogMsg}
      />
      <Stack>
        {withStepAction?.enabled && (
          <div className={cn('steps-text')}>
            {withStepAction.strings.currentStep}/{withStepAction.strings.totalStep} {withStepAction.strings.helperTxt}
          </div>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" gap={3}>
        {withDeleteAction.enabled && (
          <Button
            className={cn('delete-button')}
            type="button"
            variant="text"
            onClick={handleDialogClose}
            disabled={withDeleteAction.isDisabled}
          >
            {withDeleteAction.strings.deleteCtaLabel}
          </Button>
        )}
        {children}
      </Stack>
    </Styled>
  );
};

GridFormFooter.propTypes = {
  children: PropTypes.element,
  withDeleteAction: PropTypes.shape({
    enabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onCloseHandler: PropTypes.func,
    onConfirmHandler: PropTypes.func,
    strings: PropTypes.shape({
      dialogTitle: PropTypes.string,
      deleteDialogMsg: PropTypes.string,
      deleteCtaLabel: PropTypes.string,
    }),
  }),
  withStepAction: PropTypes.shape({
    enabled: PropTypes.bool,
    strings: PropTypes.shape({
      totalStep: PropTypes.number,
      currentStep: PropTypes.number,
      helperTxt: PropTypes.string,
    }),
  }),
};
