import { useRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker as MUIDatepicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { withBaseField } from '../hocs';
import { supportedLocales } from 'core/i18n/common';
import { Localization } from 'connex-cds';
import dayjs from 'dayjs';

/**
 * A base date field component that wraps the Material-UI DatePicker
 *
 * @param props - The DatePickerProps to pass to the underlying DatePicker.
 *
 * @returns Returns a JSX element representing the BaseDateField
 */
export const BaseDateField = props => {
  const ref = useRef(null);
  const { userLocale } = Localization.useLocalizationContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatepicker
        {...props}
        ref={ref}
        format={supportedLocales?.[userLocale]?.formats?.L || 'MM/DD/YYYY'}
        value={props.value !== '' ? dayjs(props.value) : null}
      />
    </LocalizationProvider>
  );
};

/**
 * A form-aware DatePicker component created by enhancing the `BaseDateField`
 * component with form handling capabilities.
 *
 * @param props - The properties for the DatePicker, including form handling
 * props.
 *
 * @returns A form-aware DatePicker component.
 */
export const DateField = withBaseField(BaseDateField);

/**
 * This is used by withBaseField in order to parse the input value.
 *
 * Refer to src/common/hocs/withBaseField/withBaseField.helper.js for further details
 */
DateField.defaultProps = {
  componenttype: 'DateField',
};
