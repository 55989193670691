import React from 'react';
import { useDefaultRoute } from 'connex-cds';
import { Navigate, Route, Routes } from 'react-router-dom';
import { INVTRANS_ENTITY } from 'entities/inventory-transaction';
import { INVOVERVIEW_ENTITY } from 'entities/inventory-overview';
import { AuthenticatedRoutes, RedirectToEntity } from './authenticated-routes';

export const AppRoutes = () => {
  const defaultRoute = useDefaultRoute('inventoryOverview');

  return (
    <Routes>
      <Route path="" element={<AuthenticatedRoutes />}>
        <Route path="" element={<RedirectToEntity />}>
          <Route path="/:entityRef/*">
            <Route path="inventoryOverview" element={<INVOVERVIEW_ENTITY.Views.ListInventoryOverview />} />
            <Route path="inventoryTransactions" element={<INVTRANS_ENTITY.Views.ListInventoryTransactions />} />
            <Route path="*" element={<Navigate to={defaultRoute} />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
