import { css } from '@emotion/react';

export const GridContentStyles = ({ theme }) => css`
  padding: 26px 150px;
  gap: 32px;
  grid-area: content;
  overflow-y: scroll;
  padding-top: 35px;
  position: relative;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
  }

  .loaderIcon {
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[100] })};
    animation: customLoader 1s linear infinite;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  @keyframes customLoader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
