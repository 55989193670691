import { Adapters } from './api';
import { Constants } from './constants';
import { Components } from './components';
import { Utils } from './utils';

export const CORE_ENTITY = {
  Adapters,
  Constants,
  Components,
  Utils,
};
