import { Button, DialogActions, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { DatePickerButtonStyles, PopperStyles } from './style';
import { CORE_ENTITY } from 'core';

const StyledPopper = styled(Popper)`
  ${PopperStyles}
`;

const ButtonStyled = styled(DialogActions)`
  ${DatePickerButtonStyles}
`;

export const CustomPopper = props => {
  return <StyledPopper {...props}>{props.children}</StyledPopper>;
};

export const CustomActionBar = (props, value, handleCustomSearch) => {
  const { onAccept, className } = props;
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();

  return (
    <ButtonStyled className={className}>
      <Button disabled={!value} onClick={() => handleCustomSearch(onAccept)}>
        {translateMessage('general.actions.done')}
      </Button>
    </ButtonStyled>
  );
};

CustomPopper.propTypes = {
  children: PropTypes.func,
};

CustomActionBar.propTypes = {
  className: PropTypes.string,
  onAccept: PropTypes.func,
};
