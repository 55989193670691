import React, { useMemo, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';
import { CORE_ENTITY } from 'core';
import { CustomPopper, CustomActionBar } from './CustomDateComponents';
import { supportedLocales } from 'core/i18n/common';
import styled from '@emotion/styled';
import { DatePickerStyles } from './style';
import { Stack } from '@mui/material';

const Styled = styled.div`
  ${DatePickerStyles}
`;

const DatePicker = ({ onCustomDateFilterTypeChange, value, setValue }) => {
  const [calendarOpen, setCalendarOpen] = useState(true);
  const { userLocale } = Localization.useLocalizationContext();
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();

  const localeText = useMemo(() => {
    return { start: translateMessage('component.datePicker.selectDate') };
  }, [translateMessage]);

  const handleClose = () => setCalendarOpen(false);
  const handleOpen = () => setCalendarOpen(true);
  const handleOnChange = newValue => setValue(() => newValue);

  const handleCustomSearch = onAccept => {
    if (value !== null) {
      const selectedDate = new Date(value.$d).toISOString();
      onCustomDateFilterTypeChange({ selectedDate });
    }
    onAccept();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Styled>
        <Stack>
          <MuiDatePicker
            format={supportedLocales?.[userLocale]?.formats?.L || 'MM/DD/YYYY'}
            label={localeText.start}
            onClose={handleClose}
            closeOnSelect={false}
            onOpen={handleOpen}
            open={calendarOpen}
            onChange={handleOnChange}
            slots={{
              actionBar: props => CustomActionBar(props, value, handleCustomSearch),
              popper: CustomPopper,
            }}
            slotProps={{
              actionBar: {
                actions: ['accept'],
              },
            }}
            value={value}
          />
        </Stack>
      </Styled>
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  onCustomDateFilterTypeChange: PropTypes.func,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default DatePicker;
