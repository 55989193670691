import React from 'react';
import { Stack, Icon } from 'for-sdk/common';
import { CORE_ENTITY } from 'core';
import { NoRowDataStyles } from './style';
import styled from '@emotion/styled';

const Styled = styled(Stack)`
  ${NoRowDataStyles}
`;

const NoRowsData = () => {
  const { translateToken: translateMessage } = CORE_ENTITY.Utils.useLocalizationProvider();

  return (
    <Styled>
      <Icon type="noData" />
      <div className="title">{translateMessage('info.invTrans.noRecordsTitle')}</div>
      {translateMessage('info.invTrans.noRecords')}
    </Styled>
  );
};

export default NoRowsData;
